import produce from 'immer';
import * as constants from './constants';
//import _ from 'lodash';

const initialState = {
  isLoadingSave: false,
  carts: window.localStorage.getItem("carts") === null
    ? []
    : JSON.parse(window.localStorage.getItem("carts")),
  isLoadCart: false,
  isLoadingPromotion: false,
  openPromotion: false,
  promotions: [], // chương trình khuyến mãi
  accumulations: [], // chương trình tích lỹ
  liabilities: [], // tích lỹ còn nợ khách hàng
  openPromotionDetail: false,
  detail: {},
  isSaveSuccess: false,
  orderSuccess: '',
  openPushCart: false,
  openPromotionDetail1: false,
  detail1: {},
}

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {

      case constants.UPDATE_CART_SUCCESS:
        draft.isLoadingUpdateCart = false;
        break;


      case constants.GET_CART_SUCCESS:
        draft.isLoadCart = true;
        window.localStorage.setItem(
          "carts",
          JSON.stringify(payload)
        );
        draft.carts = payload;

        break;

      case `CLOSE_NOTIFI_CART`:
        draft.openPushCart = false;
      break;
      case constants.PLUS_CART:
        draft.openPushCart = true;
        if (draft.carts.length === 0) {

          var cart = [{
            ...payload.item,
            quantity: payload.quantity,
            checked: true,
            product_name: payload.item.product_name,
            product_image: payload.item.product_image,
            product_price: payload.item.product_price,
            id_product: payload.item.id_product
          }];

          window.localStorage.setItem(
            "carts",
            JSON.stringify(cart)
          );

          draft.carts = cart;
        } else {

          const itemCart = draft.carts.filter(
            p => p.id_product === payload.item.id_product
          );

          if (itemCart.length > 0) {
            cart = draft.carts.map(product => {
              return {
                ...product,
                quantity: product.id_product === payload.item.id_product
                  ? product.quantity + payload.quantity
                  : product.quantity
              };
            });

            window.localStorage.setItem(
              "carts",
              JSON.stringify(cart)
            );

            draft.carts = cart;

          } else {

            cart = [{
              ...payload.item,
              quantity: payload.quantity,
              checked: true
            }];

            draft.carts = state.carts.concat(cart);
          }
        }

        break;
      case constants.CLEAR_CART:
        window.localStorage.removeItem('carts');
        draft.carts = [];
        break;
      case constants.CLEAR_ONE_CART:

        cart = draft.carts.filter(
          item => item.id_product !== payload.id
        );

        window.localStorage.setItem(
          "carts",
          JSON.stringify(cart)
        );
        draft.carts = cart;

        break;
      case constants.ON_CHECK_CART: // check sane phẩm
        cart = draft.carts.map((product) => {
          return {
            ...product,
            checked: product.id_product === payload.id
              ? !product.checked
              : product.checked
          };
        });

        window.localStorage.setItem(
          "carts",
          JSON.stringify(cart)
        );


        draft.carts = cart;
        break;

      case constants.ON_CHECKALL_CART:
        cart = draft.carts.map((product) => {
          return {
            ...product,
            checked: payload
          };
        });

        window.localStorage.setItem(
          "carts",
          JSON.stringify(cart)
        );
        draft.carts = cart;
        break;
      case constants.CHANGE_QUANTITY_CART:
        cart = draft.carts.map(product => {
          var quantity = product.quantity;

          if (product.id_product === payload.id) {
            quantity = payload.quantity;
          }
          return { ...product, quantity };
        });

        window.localStorage.setItem(
          "carts",
          JSON.stringify(cart)
        );

        draft.carts = cart;

        break;

      case constants.GET_PROMOTION_START:
        draft.openPromotion = false;
        draft.isLoadingPromotion = true;
        draft.promotions = [];
        draft.accumulations = [];
        draft.liabilities = [];
        break;

      case constants.GET_PROMOTION_SUCCESS:
        draft.openPromotion = true;
        draft.isLoadingPromotion = false;
        draft.promotions = payload.promotions;
        draft.accumulations = payload.accumulations;
        draft.liabilities = payload.liabilities;
        break;

      case constants.GET_PROMOTION_ERROR:
        draft.openPromotion = false;
        draft.isLoadingPromotion = false;
        draft.promotions = [];
        draft.accumulations = [];
        draft.liabilities = [];
        break;

      case constants.CART_PROMOTION_CHANGE:
        draft.openPromotion = payload;
        break;

      case constants.CART_PROMOTION_DETAIL_CLOSE:
        draft.openPromotion = true;
        draft.openPromotionDetail = false;
        draft.detail = {};
        break;

      case constants.CART_PROMOTION_DETAIL_OPEN:
        draft.openPromotion = false;
        draft.openPromotionDetail = true;
        draft.detail = payload;
        break;

        case constants.CART_PROMOTION_DETAIL1_CLOSE:
          draft.openPromotionDetail1 = false;
          draft.detail = {};
          break;
  
        case constants.CART_PROMOTION_DETAIL1_OPEN:
          draft.openPromotionDetail1 = true;
          draft.detail1 = payload;
          break;

      case constants.CART_ORDER_SAVE_START:
        draft.isLoadingSave = true;
        draft.isSaveSuccess = false;
        draft.orderSuccess = '';
        break;

      case constants.CART_ORDER_SAVE_SUCCESS:
        draft.isLoadingSave = false;
        draft.isSaveSuccess = true;

        cart = draft.carts.filter(
          item => !item.checked
        );

        window.localStorage.setItem(
          "carts",
          JSON.stringify(cart)
        );

        draft.carts = cart;
        draft.orderSuccess = payload;
        break;

      case constants.CART_ORDER_SAVE_ERROR:
        draft.isSaveSuccess = false;
        draft.isLoadingSave = false;
        draft.orderSuccess = '';
        break;


      case constants.CART_ORDER_RESET:
        draft.isSaveSuccess = false;
        draft.isLoadingSave = false;
        break;


        case constants.ADD_CART_REPURCHASE:
          cart = payload.cart.map((item) => {
            return {...item, checked: true}
          });
          window.localStorage.setItem(
            "carts",
            JSON.stringify(cart)
          );
          draft.carts = cart;
        break;

      case `RESET_REDUCER`:
        draft.isSaveSuccess = false;
        draft.orderSuccess = '';
        draft.promotions = [];
        draft.openPromotion = false;
        draft.openPromotionDetail = false;
        break;


        

      default:
        break;
    }
  });

export default reducer;