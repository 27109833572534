import ascusapi from '../../../apis/ascusapi';

export const getTrademarks = async (params) => {
    const respone = await ascusapi.get('ecommerce/trademarks', {params});
    return respone;
}


export const getProducts = async (params) => {
    const respone = await ascusapi.get('ecommerce/search-product', {params});
    return respone;
}

export const getBanner = async (params) => {
    const respone = await ascusapi.get('ecommerce/banner', {params});
    return respone;
}
