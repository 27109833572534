
import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import editavt from '../../../../assets/editavt.svg';
import { Upload, message } from 'antd';
import { isAuthenticated } from '../../../shared/routes/permissionCusChecker';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
    button: {
        minWidth: 48,
        height: 48,
        borderRadius: 48,
    },
    upload: {
        position: 'absolute',
        bottom: 0,
        right: 45
    }
}));

const ButtonUpload = ({
    setLoading
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    
    return (
        <Upload
            className={classes.upload}
            accept="image/png, image/jpeg"
            action={`${process.env.REACT_APP_API_URI}ecommerce/auth/upload-avatar`}
            onChange={({ file, fileList }) => {
                if (file.status === 'uploading') {
                    setLoading(true);
                }else if (file.status === 'done') {
                    message.success(`Cập nhật ảnh đại diện thành công`);
                    dispatch({type: `USER_UPDATE_AVATAR`, payload: {
                        avatar_thumb: file.response.avatar_thumb,
                        avatar: file.response.avatar,
                        avatar_name: file.response.avatar_name,
                    }});
                    setLoading(false);
                }else if (file.status === 'error') {
                    message.error(`Có lỗi trong quá trình vui lòng thử lại`);
                    setLoading(false);
                }
            }}
            headers={{
                'Authorization': `Bearer ${isAuthenticated()}`
            }}
            showUploadList={false}
            name="file"
            disabled={false}
        >
            <Button
                title='Cập nhật ảnh'
                variant='contained'
                color='primary'
                className={classes.button}
            >
                <img src={editavt} alt='upload file' />

            </Button>
        </Upload>

    )
}

export default ButtonUpload;
