import React, { useEffect } from 'react';
import 'antd/dist/antd.min.css';


import CssBaseline from '@material-ui/core/CssBaseline';
import RoutesComponent from './pages/shared/routes/RoutesComponent';
import {ThemeProvider } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { history, configureStore } from './pages/configureStore'; // tích hợp middleware+devtool
import theme from './Theme';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

function App() {

  return (
    <Provider store={configureStore()}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ConnectedRouter history={history} >
          <RoutesComponent />
        </ConnectedRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
