import React from 'react';
import PropTypes from 'prop-types';

const SearchIcon = () => {
    return (
        <div>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="#12A79D" />
                <path d="M30.0786 29.0385L24.8022 23.5928C26.1588 21.9924 26.9022 19.9788 26.9022 17.8825C26.9022 12.9848 22.8866 9 17.9511 9C13.0155 9 9 12.9848 9 17.8825C9 22.7802 13.0155 26.765 17.9511 26.765C19.804 26.765 21.5697 26.2104 23.0793 25.1576L28.3958 30.6447C28.618 30.8737 28.9169 31 29.2372 31C29.5404 31 29.828 30.8853 30.0463 30.6768C30.5102 30.2338 30.525 29.4992 30.0786 29.0385ZM17.9511 11.3172C21.5992 11.3172 24.5671 14.2623 24.5671 17.8825C24.5671 21.5027 21.5992 24.4478 17.9511 24.4478C14.3029 24.4478 11.3351 21.5027 11.3351 17.8825C11.3351 14.2623 14.3029 11.3172 17.9511 11.3172Z" fill="white" />
            </svg>
        </div>
    );
};

SearchIcon.propTypes = {

};


export default SearchIcon;
