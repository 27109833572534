import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography,Grid } from '@material-ui/core';
import FreeShippingIcon from '../../Core/Icons/FreeShippingIcon'
import TowFourSeventIcon from '../../Core/Icons/TowFourSeventIcon';
import FastShippingIcon from '../../Core/Icons/FastShippingIcon';
import PromotionIcon from '../../Core/Icons/PromotionIcon';
import { container } from '../../../Theme/master'
const useStyles = makeStyles((theme) => ({
  wrapFooter: {
    background: '#FFFFFF',

  },
  wrapIconTextFooter: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(821)]: {
      paddingBottom: 10
    }
  },
 
  backGround: {
    ...container,
    padding: '40px 0px 40px 0px',
  },
  paddingFooter:{
    paddingRight:'16px'
  },
  Footer:{
    display: 'flex',
  },
  tilted: {
    fontWeight: '700',
    fontSize: '18px',
    paddingBottom:'8px'
},
}))

const Footer2 = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapFooter}>
      <Grid container className={classes.backGround}>
        <Grid item sm={12} md={3} xs={12} className={classes.wrapIconTextFooter}>
          <div className={classes.paddingFooter}>
            <FreeShippingIcon />
          </div>
          <div >
            <Typography  className={classes.tilted} >
              Miễn phí vận chuyển
            </Typography>
            <Typography gutterBottom >
              Mạng lưới giao hàng toàn quốc
            </Typography>
          </div>
        </Grid>
        <Grid item sm={12} md={3} xs={12} className={classes.wrapIconTextFooter}>
          <div className={classes.paddingFooter}>
            <TowFourSeventIcon />
          </div>
          <div className={classes.text} >
            <Typography   className={classes.tilted} >
            Đổi trả miễn phí
            </Typography>
            <Typography  >
              Tư vấn hỗ trợ miễn phí
            </Typography>
          </div>
        </Grid>
        <Grid item sm={12} md={3} xs={12} className={classes.wrapIconTextFooter}>
          <div className={classes.paddingFooter}>
            <FastShippingIcon />
          </div>
          <div className={classes.text}>
            <Typography className={classes.tilted} >
              Giao hàng nhanh chóng
            </Typography>
            <Typography  >
              Giao hàng nhanh chóng trong 24h
            </Typography>
          </div>
        </Grid>
        <Grid item sm={12} md={3} xs={12} className={classes.Footer}>
          <div className={classes.paddingFooter}>
            <PromotionIcon />
          </div>
          <div className={classes.text}>
            <Typography className={classes.tilted} >
              Khuyến mãi hấp dẫn
            </Typography>
            <Typography  >
            Chương trình khuyến mãi đa dạng
            </Typography>
          </div>
        </Grid>
    </Grid>
    </div>

  );
};


Footer2.propTypes = {

};


export default Footer2;
