import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
// import { Typography } from 'antd';
const useStyles = makeStyles((theme) => ({
    copyright: {
        textAlign: 'center',
        fontSize: '14px',
        padding: '20px',
    }
}))
const Copyright = () => {
    const classes = useStyles();

    return (

        <div className={classes.copyright} >
            <Typography className='text-dark' >
                Công ty cổ phần dược Pha Nam - Thành viên tập đoàn Merap © 2022
            </Typography>

        </div>
    );
};


Copyright.propTypes = {

};


export default Copyright;




