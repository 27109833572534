import React from 'react';
import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core';
import _ from 'lodash';
import parse from 'html-react-parser';
import AllProductIcon from '../../Core/Icons/AllProductIcon';
import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  category: {
    width: 325,
    height: 68,
    background: '#F7FAFB',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    color: '#12A79D',
    fontWeight: '600',
    '&:hover': {
      background: '#F7FAFB',
    },
    boxSizing: 'border-box',
    marginTop: 5,
    borderBottom: '1px solid #FFFFFF',

  },
  list: {
    marginTop: '70px',

  },
  listName: {
    width: '320px',
    padding: '20px 20px 10px 40px',
  },
  text: {
    paddingLeft: '20px',
    color: 'inherit',
    "&:hover": {
      color: '#00A79D',
    }
  },
  iconCat: {
    '& svg': {
      '& path': {
        fill: '#3C5264',
      }
      
    }
  }
}))

const Category = (props) => {
  // console.log(props)
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  var parser = new DOMParser();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log('đ')
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {

    setAnchorEl(null);
  };
  return (
    <div    >
      <Button
      
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={classes.category}
        startIcon={
          <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 12.75C0 12.3398 0.339844 12 0.75 12H17.25C17.6602 12 18 12.3398 18 12.75V14.25C18 14.6602 17.6602 15 17.25 15H0.75C0.339844 15 0 14.6602 0 14.25V12.75ZM0 6.75C0 6.33984 0.339844 6 0.75 6H12.25C12.6602 6 13 6.33984 13 6.75V8.25C13 8.66016 12.6602 9 12.25 9H0.75C0.339844 9 0 8.66016 0 8.25V6.75ZM0 0.75C0 0.339843 0.339844 0 0.75 0H7.25C7.66016 0 8 0.339843 8 0.75V2.25C8 2.66016 7.66016 3 7.25 3H0.75C0.339844 3 0 2.66016 0 2.25V0.75Z" fill="#12A79D" />
          </svg>
        }
      >
        DANH MỤC

      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.list}
      >
        <MenuItem onClick={handleClose} className={classes.listName}>
          <AllProductIcon />
            <Link className={classes.text} to="/search" >Tất cả sản phẩm</Link>
        </MenuItem>
        {!_.isEmpty(props.category) ?
          props.category.filter(val => val.menu_vertical === 1).map((val, i) => (
            <MenuItem onClick={handleClose} className={classes.listName} key={i}>
              {
                <span className={classes.iconCat}>{val.icon ? parse(val.icon) : ''}</span>
              }
              <Link className={classes.text} to={`/search?id_category=${val.id_category}`} >{val.category_name}</Link>

            </MenuItem>
          )) : ''
        }
      </Menu>
    </div>
  );
};




export default Category;
