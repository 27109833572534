import React from 'react';
import PropTypes from 'prop-types';


const MarkerIcon = () => {
    return (
      <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2297_2412)">
      <path d="M24 8.59004C23.9145 8.99926 23.8487 9.41374 23.7398 9.81626C23.3809 11.143 22.737 12.3309 21.9653 13.4471C20.4158 15.6889 18.5417 17.6177 16.4451 19.3283C16.4211 19.3479 16.3948 19.3642 16.345 19.4001C15.9308 19.0445 15.5048 18.6975 15.0994 18.3275C13.2315 16.6208 11.5199 14.7728 10.1997 12.5817C9.53929 11.4852 9.00056 10.3308 8.8108 9.04425C8.48296 6.82585 8.97613 4.80656 10.3246 3.03089C11.5585 1.40694 13.1953 0.43869 15.1811 0.100786C19.0814 -0.562579 22.9596 2.1196 23.8079 6.05959C23.8858 6.4219 23.9366 6.79092 24 7.15658C24 7.63424 24 8.1119 24 8.59004ZM16.3478 16.9027C16.415 16.8462 16.4648 16.8094 16.5085 16.7663C17.0857 16.1958 17.6794 15.6415 18.2346 15.0495C19.4074 13.7979 20.4731 12.4602 21.2772 10.9243C21.9155 9.70427 22.2593 8.42731 22.0761 7.02783C21.6572 3.82587 18.7667 1.57014 15.5982 1.98798C12.813 2.3546 10.6806 4.7654 10.5923 7.6261C10.5562 8.80016 10.8906 9.86843 11.4232 10.8841C12.6519 13.2283 14.4329 15.1055 16.3478 16.9027Z" fill="white"/>
      <path d="M3.26611e-06 2.86226C0.00235169 1.2991 1.26909 0.0101776 2.80684 0.00587006C4.36291 0.0015625 5.62637 1.30005 5.62355 2.8996C5.62073 4.4642 4.35634 5.75072 2.81718 5.75551C1.26205 5.76078 -0.00234516 4.46181 3.26611e-06 2.86226ZM2.79369 3.82429C3.31645 3.83099 3.74011 3.41794 3.74903 2.89337C3.75749 2.38221 3.34604 1.94954 2.84113 1.93853C2.3071 1.92752 1.87405 2.34918 1.87405 2.88045C1.87358 3.39401 2.2869 3.81806 2.79369 3.82429Z" fill="white"/>
      <path d="M3.74821 8.24212C3.74493 8.76526 3.3161 9.19841 2.80649 9.19362C2.28373 9.18883 1.86853 8.74898 1.87558 8.20766C1.88215 7.69602 2.30017 7.27388 2.80555 7.26861C3.3255 7.26287 3.7515 7.7032 3.74821 8.24212Z" fill="white"/>
      <path d="M3.74813 11.6701C3.74813 12.1961 3.33105 12.6278 2.81768 12.6331C2.29821 12.6383 1.87173 12.197 1.87502 11.6586C1.87831 11.1355 2.30666 10.7033 2.81674 10.708C3.33151 10.7128 3.74813 11.1431 3.74813 11.6701Z" fill="white"/>
      <path d="M3.74819 15.122C3.74491 15.6446 3.31561 16.0778 2.80553 16.0725C2.28231 16.0672 1.86804 15.6279 1.87509 15.0861C1.88166 14.5749 2.30062 14.1523 2.80553 14.1475C3.32642 14.1427 3.75195 14.5826 3.74819 15.122Z" fill="white"/>
      <path d="M3.74813 18.5963C3.7486 19.1223 3.33151 19.555 2.81815 19.5607C2.29961 19.5665 1.87173 19.1247 1.87502 18.5868C1.87831 18.0632 2.30525 17.631 2.8158 17.6348C3.33011 17.6391 3.74766 18.0699 3.74813 18.5963Z" fill="white"/>
      <path d="M3.74811 22.0314C3.74999 22.5589 3.3376 22.9906 2.82283 23.0002C2.30617 23.0097 1.87359 22.567 1.875 22.0305C1.87688 21.5059 2.30007 21.0737 2.81156 21.0742C3.32398 21.0747 3.7467 21.5069 3.74811 22.0314Z" fill="white"/>
      <path d="M7.21711 22.031C7.21898 22.5589 6.80754 22.9901 6.29229 23.0002C5.77564 23.0102 5.34259 22.5675 5.344 22.031C5.34588 21.5064 5.76859 21.0742 6.28055 21.0742C6.79345 21.0742 7.21523 21.5055 7.21711 22.031Z" fill="white"/>
      <path d="M10.5924 22.0368C10.5924 22.5633 10.1757 22.995 9.66191 23.0002C9.14385 23.0055 8.71597 22.5633 8.71926 22.0253C8.72254 21.5021 9.15137 21.0695 9.66144 21.0743C10.1762 21.079 10.5924 21.5093 10.5924 22.0368Z" fill="white"/>
      <path d="M13.9674 22.0446C13.9651 22.5692 13.5409 23.0013 13.0295 23.0004C12.509 22.9989 12.0882 22.5572 12.0943 22.0178C12.0999 21.4971 12.5372 21.063 13.0445 21.0744C13.5616 21.0859 13.9698 21.5152 13.9674 22.0446Z" fill="white"/>
      <path d="M17.3426 22.0436C17.3408 22.5682 16.9166 23.0004 16.4052 22.9994C15.8847 22.9985 15.4639 22.5562 15.4695 22.0168C15.4752 21.4961 15.912 21.0624 16.4192 21.0734C16.9364 21.0854 17.3445 21.5143 17.3426 22.0436Z" fill="white"/>
      <path d="M13.0789 7.7822C13.0807 5.94718 14.5602 4.44192 16.3615 4.44336C18.1721 4.4448 19.6422 5.95484 19.6399 7.81092C19.6376 9.64929 18.1609 11.1498 16.3568 11.1474C14.541 11.145 13.077 9.64163 13.0789 7.7822ZM16.3606 9.21901C17.1346 9.21806 17.7659 8.57814 17.7644 7.79512C17.763 7.01306 17.1299 6.37459 16.3559 6.37554C15.5828 6.3765 14.9501 7.01785 14.952 7.79943C14.9543 8.58245 15.5865 9.21949 16.3606 9.21901Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_2297_2412">
      <rect width="24" height="23" fill="white"/>
      </clipPath>
      </defs>
      </svg>
      
    );
};


MarkerIcon.propTypes = {

};


export default MarkerIcon;
