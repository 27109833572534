import ascusapi from '../../../apis/ascusapi';

export const getCustomerInfo = async (params) => { // lấy thông tin khách hàng
    const respone = await ascusapi.get('ecommerce/auth/user-info', {params});
    return respone;
}

/**
 *  lấy thông tin CTKM
 * @param {*} data 
 * @returns 
 */
export const getPromotion = async (data) => {
    const respone = await ascusapi.post('ecommerce/promotion', data);
    return respone;
}

export const createOrder = async (data) => { // post ĐH
    const respone = await ascusapi.post('ecommerce/order/create', data);
    return respone;
}
export const clearCartOrder = async () => { // xóa giỏ hàng
    const respone = await ascusapi.post('ecommerce/order/clear-cart');
    return respone;
}
export const clearCart = async (data) => { // xóa giỏ hàng
    const respone = await ascusapi.post('ecommerce/order/clear-cart-by-product',data);
    return respone;
}
export const updateCartOrder = async (data) => { // cập nhật giỏ hàng
    const respone = await ascusapi.post('ecommerce/order/update-cart', data);
    return respone;
}

export const getCartOrder = async () => { // cập nhật giỏ hàng
    const respone = await ascusapi.post('ecommerce/order/get-cart');
    return respone;
}

export const checkedCart = async (data) => { // xóa giỏ hàng
    const respone = await ascusapi.post('ecommerce/order/checked-cart',data);
    return respone;
}