import {getHistory} from '../../configureStore';
import Message from '../message';
import parse from 'html-react-parser';
//import _ from 'lodash';
const DEFAULT_ERROR_MESSAGE = "Something went wrong!";
import { infoSuccess } from './../../../utils/messageCustom';


function selectErrorMessage(error) {
  if (error?.response && error?.response?.data && error?.response?.data?.errors) {
      let errorMsg = "";

      for (const [key, value] of Object.entries(error?.response?.data?.errors)) {
        errorMsg += `${value}` + "\n";
      }

      return errorMsg;
  }
  if (error?.response) {
    
      return error?.response?.data?.message;

  }
  return error?.message || DEFAULT_ERROR_MESSAGE;
}

function selectErrorCode(error) {
  if (error?.response) {
    return error?.response?.status;
  }
  return 500;
}

export default class Errors {
  static handle(error) {

    if (selectErrorCode(error) === 401) {
      // gửi yêu cầu token mới với refresh token và email 
      window.localStorage.removeItem("authcus");
      window.localStorage.removeItem("carts");
      getHistory().push("/login");
      // Message.error("THÔNG BÁO", "Unauthorized", () => {
        
      // });
      return;

    }

    if(selectErrorCode(error) === 406) {
      getHistory().push("/locked");
      return;
    }

    if (selectErrorCode(error) === 403) {
      Message.error("You don't have permission to access this resource");
      return;
    }

    if (
      selectErrorCode(error) === 400 ||
      selectErrorCode(error) === 409 ||
      selectErrorCode(error) === 404
    ) {
      Message.error(selectErrorMessage(error));
      return;
    }

    // getHistory().push("/500");
    Message.error(selectErrorMessage(error));
  }

  static errorCode(error) {
    return selectErrorCode(error);
  }

  static selectMessage(error) {
    return selectErrorMessage(error);
  }

  static showMessage(error) {
    Message.error(selectErrorMessage(error));
  }
}

