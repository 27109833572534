import React from 'react';
import PropTypes from 'prop-types';


const CartIcon = () => {
    return (
        <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.80876 1.7724C5.75493 1.58159 5.49818 1.38736 5.29997 1.38736H1.04124C0.658144 1.38736 0.347561 1.07687 0.347561 0.69368C0.347561 0.310491 0.658144 0 1.04124 0H5.29997C6.11296 0 6.9229 0.612843 7.14387 1.39522L8.29436 5.46694C8.42588 5.43031 8.56443 5.40913 8.70853 5.40913H22.468C22.9059 5.40913 23.3217 5.59078 23.6086 5.90747C23.8955 6.22416 24.0354 6.65572 23.9923 7.09135L23.3506 13.596C23.2651 14.4629 22.5157 15.142 21.6445 15.142H10.9651C10.1487 15.142 9.34227 14.5246 9.12918 13.7366L7.79121 8.78892L5.80876 1.7724ZM10.4685 13.3745C10.5194 13.5628 10.7701 13.7547 10.9652 13.7547H21.6446C21.797 13.7547 21.955 13.6114 21.97 13.4598L22.6118 6.95511C22.6166 6.90702 22.6058 6.86688 22.5806 6.83913C22.5554 6.81129 22.5165 6.79658 22.4681 6.79658H8.70853C8.70178 6.79658 8.69586 6.79686 8.69068 6.79723C8.6916 6.80241 8.69299 6.80814 8.69475 6.81462L10.4685 13.3745Z" fill="#3C5264" />
            <path d="M19.6199 15.9326C20.8097 15.9326 21.7776 16.9005 21.7776 18.0902C21.7776 19.2799 20.8097 20.2479 19.6199 20.2479C18.4302 20.2479 17.4623 19.2799 17.4623 18.0902C17.4623 16.9005 18.4302 15.9326 19.6199 15.9326ZM19.6199 18.8605C20.0447 18.8605 20.3902 18.515 20.3902 18.0902C20.3902 17.6655 20.0447 17.32 19.6199 17.32C19.1952 17.32 18.8496 17.6655 18.8496 18.0902C18.8496 18.515 19.1952 18.8605 19.6199 18.8605Z" fill="#3C5264" />
            <path d="M12.7784 15.9326C13.9681 15.9326 14.936 16.9005 14.936 18.0902C14.936 19.2799 13.9681 20.2479 12.7784 20.2479C11.5887 20.2479 10.6208 19.2799 10.6208 18.0902C10.6208 16.9005 11.5886 15.9326 12.7784 15.9326ZM12.7784 18.8605C13.2031 18.8605 13.5487 18.515 13.5487 18.0902C13.5487 17.6655 13.2031 17.32 12.7784 17.32C12.3537 17.32 12.0082 17.6655 12.0082 18.0902C12.0082 18.515 12.3536 18.8605 12.7784 18.8605Z" fill="#3C5264" />
        </svg>
    );
};


CartIcon.propTypes = {

};


export default CartIcon;
