import React, { Fragment } from "react";
import Header from "../../Layout/HomeLayout/Header";
import Footer from "../../Layout/HomeLayout/Footer";
import { Route } from "react-router-dom";
import _ from "lodash";

function HomePublicRoute({ component: Component, ...rest }) {

    return (
        <Route
            {...rest}
            render={props =>
                <Fragment>
                    <Header />
                    <Component {...props} />
                    <Footer />
                </Fragment>


            }
        />
    )
}

export default HomePublicRoute;