import { FormatMoney } from 'format-money-js';

const fm = new FormatMoney({
    decimals: 0,
    separator: '.',
    decimalPoint: ',',
    symbol: ' đ',
    append: true
});

const fmSum = new FormatMoney({
  decimals: 0,
  separator: '.',
  decimalPoint: ',',
  symbol: '',
  append: true
});

export const formatPrice = value => {
    return fm.from(value);
}

export const formatNumber = value => {
  return fmSum.from(value);
}

export function totalTemporary (datas) {
    var totalPrice = 0;
    if (datas.length > 0) {
      for (let index = 0; index < datas.length; index++) {
        if (datas[index].checked) {
          totalPrice += datas[index].quantity * datas[index].product_price;
        }
      }
    }
  return totalPrice;
}