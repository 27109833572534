import produce from 'immer';
import * as constants from './constants';

const initialState = {
    isLodListAccums: false,
    accums: [],
    isLoadStatistics: false,
    statistics: {
        liabilities: {
            
        },
        accumulations: []
    },
    isLoadOrder: false,
    orderHistory: {
        data: [],
        total: 0,
    },
    isloadJoin: false,
    joinHistory: {
        data: [],
        total: 0,
    },
}


const reducer = (state = initialState, { type, payload }) =>
    produce(state, draft => {
        switch (type) {
            case constants.FIND_LIST_ACCUM_START: {
                draft.isLodListAccums = true;
                draft.accums = [];
                break;
            }

            case constants.FIND_LIST_ACCUM_SUCCESS: {
                draft.isLodListAccums = false;
                draft.accums = payload;
                break;
            }

            case constants.FIND_LIST_ACCUM_ERROR: {
                draft.isLodListAccums = false;
                draft.accums = [];
                break;
            }

            case constants.FIND_STATISTICS_ACCUM_START: {
                
                draft.isLoadStatistics = true;
                break;
            }

            case constants.FIND_STATISTICS_ACCUM_SUCCESS: {
                draft.isLoadStatistics = false;
                draft.statistics = payload;
                break;
            }

            case constants.FIND_STATISTICS_ACCUM_ERROR: {
                draft.isLoadStatistics = false;
                draft.statistics = {liabilities: {}, accumulations: []};
                break;
            }

            case constants.FIND_ACCUM_ORDER_HISTORY_START: {
                draft.isLoadOrder = true;
                break;
            }

            case constants.FIND_ACCUM_ORDER_HISTORY_SUCCESS: {
                draft.isLoadOrder = false;
                draft.orderHistory = payload;
                break;
            }

            case constants.FIND_ACCUM_ORDER_HISTORY_ERROR: {
                draft.isLoadOrder = false;
                
                break;
            }


            case constants.FIND_ACCUM_JOIN_HISTORY_START: {
                draft.isloadJoin = true;
                break;
            }

            case constants.FIND_ACCUM_JOIN_HISTORY_SUCCESS: {
                draft.joinHistory = payload;
                draft.isloadJoin = false;
                break;
            }

            case constants.FIND_ACCUM_JOIN_HISTORY_ERROR: {
                draft.isloadJoin = false;
                break;
            }
                
        
        default:
            break;
    }
});

export default reducer;