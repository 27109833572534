import React from 'react';
import {
    makeStyles,
} from '@material-ui/core';
import backgroundLogin from '../../../assets/backgroundLogin.svg';
import Header from './Header';


const useStyles = makeStyles((theme) => ({
    backgroundLogin: {
        backgroundImage: `url(${backgroundLogin})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down(821)]: {
            backgroundImage: 'none',
            height: 'calc(100vh - 102px)',
            marginTop: 10
        }
    },

}))
const Content = (props) => {
    const classes = useStyles();
    return (

        <React.Fragment>
            <Header />
            <section className={classes.backgroundLogin}>
            {props.children}
        </section>
        </React.Fragment>
    )
}

export default Content;
