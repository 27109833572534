import produce from 'immer';
import * as constants from './constants';
//import _ from 'lodash';

const initialState = {
    isLoadingBanner: false,
    isLoadingProduct: false,
    isLoadingTrademarks: false,
    isRedMoreLoading: false,
    banner: [],
    trademarks: [],
    products: {
        data: [],
        total: 0
    }
}

const reducer = (state = initialState, { type, payload }) =>
    produce(state, draft => {

        switch (type) {

            case constants.GET_TRADEMARKS_START:
                draft.isLoadingTrademarks = true;
                draft.trademarks = [];
            break;


            case constants.GET_TRADEMARKS_SUCCESS:
                draft.isLoadingTrademarks = false;
                draft.trademarks = payload;
                
            break;

            case constants.GET_TRADEMARKS_ERROR:
                draft.isLoadingTrademarks = false;
                draft.trademarks = [];
            break;

            case constants.GET_BANER_FILTER_START:
                draft.isLoadingBanner = true;
                draft.banner = [];
            break;

            case constants.GET_BANER_FILTER_SUCCESS:
                draft.isLoadingBanner = false;
                draft.banner = payload;
            break;

            case constants.GET_BANER_FILTER_ERROR:
                draft.isLoadingBanner = false;
                draft.banner = [];
            break;

            case constants.GET_PRODUCT_FILTER_START:
                draft.isLoadingProduct = true;
            break;

            case constants.GET_PRODUCT_FILTER_SUCCESS:
                draft.isLoadingProduct = false;
                draft.products = payload;
            break;

            case constants.GET_PRODUCT_FILTER_ERROR:
                draft.isLoadingProduct = false;
                draft.products = {
                    data: [],
                    total: 0
                };
            break;


            case constants.GET_PRODUCT_READMORE_START:
                draft.isRedMoreLoading = true;
            break;

            case constants.GET_PRODUCT_READMORE_SUCCESS:
                draft.isRedMoreLoading = false;
                draft.products.total = payload.total;
                draft.products.data = draft.products.data.concat(
                    payload.data
                );
            break;

            case constants.GET_PRODUCT_READMORE_ERROR:
                draft.isRedMoreLoading = false;
            break;

            case `RESET_STATE`:
                draft.isLoadingBanner = false;
                draft.isLoadingProduct = false;
                draft.isLoadingTrademarks = false;
                draft.products = {
                    data: [],
                    total: 0
                };
                break;

            default:
                break;
        }
    });

export default reducer;