export const USER_LOGIN_START = `USER_LOGIN_START`;
export const USER_LOGIN_SUCCESS = `USER_LOGIN_SUCCESS`;
export const USER_LOGIN_ERROR = `USER_LOGIN_ERROR`;


export const USER_RESET_START = `USER_RESET_START`;
export const USER_RESET_SUCCESS = `USER_RESET_SUCCESS`;
export const USER_RESET_ERROR = `USER_RESER_ERROR`;


export const USER_LOGIN_KEY_START = `USER_LOGIN_KEY_START`;
export const USER_LOGIN_KEY_SUCCESS = `USER_LOGIN_KEY_SUCCESS`;
export const USER_LOGIN_KEY_ERROR = `USER_LOGIN_KEY_ERROR`;

export const USER_GET_START = `USER_GET_START`;
export const USER_GET_SUCCESS = `USER_GET_SUCCESS`;
export const USER_GET_ERROR = `USER_GET_ERROR`;

export const USER_GET_BUSINESS_SCOPE_START = `USER_GET_BUSINESS_SCOPE_START`;
export const USER_GET_BUSINESS_SCOPE_SUCCESS = `USER_GET_BUSINESS_SCOPE_SUCCESS`;
export const USER_GET_BUSINESS_SCOPE_ERROR = `USER_GET_BUSINESS_SCOPE_ERROR`;


export const USER_UPDATE_CUSTOMER_START = `USER_UPDATE_CUSTOMER_START`;
export const USER_UPDATE_CUSTOMER_SUCCESS = `USER_UPDATE_CUSTOMER_SUCCESS`;
export const USER_UPDATE_CUSTOMER_ERROR = `USER_UPDATE_CUSTOMER_ERROR`;


export const USER_UPDATE_START = `USER_UPDATE_START`;
export const USER_UPDATE_SUCCESS = `USER_UPDATE_SUCCESS`;
export const USER_UPDATE_ERROR = `USER_UPDATE_ERROR`;

export const USER_CHANGE_PASS_START = `USER_CHANGE_PASS_START`;
export const USER_CHANGE_PASS_SUCCESS = `USER_CHANGE_PASS_SUCCESS`;
export const USER_CHANGE_PASS_ERROR = `USER_CHANGE_PASS_ERROR`;

export const USER_UPDATE_AVATAR = `USER_UPDATE_AVATAR`;