import React from 'react'
import { makeStyles, Paper, Typography, CardMedia, Divider } from '@material-ui/core';
import {
    formatPrice
} from '../../../../utils/formatMoney';
import { Link, useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        width: 450,
        boxShadow: '0px 0px 10px rgba(35, 46, 45, 0.15)',
        position: 'absolute',
        zIndex: 10,
        padding: 10,
        marginTop: 10,
        borderRadius: 10
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        padding: 5,
        '&:hover': {
            background: '#f5f5f5',
        },
        fontSize: 13
    },
    wpImage: {
        padding: 10,
        borderRadius: 100,
        background: '#f5f5f5'
    },
    image: {
        width: 35
    },
    productName: {
        fontWeight: 600,
        color: '#102647'
    },
    price: {
        fontWeight: 600,
        color: theme.palette.primary.main
    },
    content: {
        flexGrow: 1,
        paddingLeft: 10
    },
    description: {
        color: '#bfbfbf'
    },
    divider: {
        borderTop: '1px dashed #d9d9d9'
    },
    footer: {
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&>p': {
            cursor: 'pointer',
            color: theme.palette.primary.main
        }
    },
    header: {
        height: 30,
        fontSize: 16,
        fontWeight: 600,
        color: '#52627C'
    }
}));

const ListProductSearch = ({
    keyword,
    products,
    setOpenSearch
}) => {
    const classes = useStyles();
    const { data, total } = products;
    const history = useHistory();
    return (
        <Paper elevation={0} className={classes.root}>
            <div className={classes.header}>
                {total} kết quả với "{keyword}"
            </div>
            {data.map((product, index) => {
                return (
                    <React.Fragment key={index} >
                        {index !== 0 && <div className={classes.divider} />}
                        <Link
                            key={index}
                            to={`/product/${product.slug}`}
                        >
                        <div 
                            className={classes.item}
                        >
                            <div className={classes.wpImage}>
                                <CardMedia
                                    className={classes.image}
                                    component="img"
                                    image={
                                    product.product_image !== null
                                        && product.product_image !== ''
                                        ? process.env.REACT_APP_LINK_IMAGE_PRODUCT + product.product_image
                                        : ''
                                    }
                                />

                                
                            </div>
                            <div className={classes.content}>
                                <div className={classes.productName}>{product.product_name}</div>
                                <div className={classes.price}>{formatPrice(product.product_price)}</div>
                            </div>
                            
                        </div>
                        </Link>
                    </React.Fragment>
                )
            } )}

            {total > 5 && (
                <div className={classes.footer}>
                    <p onClick={() => {
                        setOpenSearch(false);
                        history.push(`/search?keyword=${keyword}`);
                    }}>Xem thêm</p>
                </div>
            )}
        </Paper>
    )
}

export default ListProductSearch;
