export const CART_ORDER_SAVE_START = `CART_ORDER_SAVE_START`;
export const CART_ORDER_SAVE_SUCCESS = `CART_ORDER_SAVE_SUCCESS`;
export const CART_ORDER_SAVE_ERROR = `CART_ORDER_SAVE_ERROR`;

export const CART_ORDER_RESET = `CART_ORDER_RESET`;


export const UPDATE_CART_START = `UPDATE_CART_START`;


export const PLUS_CART = "PLUS_CART";
export const EXCEPT_CART = "EXCEPT_CART";
export const CLEAR_CART = "CLEAR_CART";
export const CHANGE_QUANTITY_CART = "CHANGE_QUANTITY_CART";
export const CHANGE_INPUT_QUANTITY = "CHANGE_INPUT_QUANTITY";

export const CLEAR_ONE_CART = "CLEAR_ONE_CART";
export const ON_CHECK_CART = "ON_CHECK_CART";
export const ON_CHECKALL_CART = "ON_CHECKALL_CART";
export const SUBMIT_CART = "SUBMIT_CART";

export const ADD_CART_REPURCHASE = `ADD_CART_REPURCHASE`;

export const UPDATE_CART_REQUEST = `UPDATE_CART_REQUEST`;
export const UPDATE_CART_SUCCESS = `UPDATE_CART_SUCCESS`;
export const UPDATE_CART_ERROR = `UPDATE_CART_ERROR`;

export const GET_CART_START = `GET_CART_START`;
export const GET_CART_SUCCESS = `GET_CART_SUCCESS`;
export const GET_CART_ERROR = `GET_CART_ERROR`;

export const GET_PROMOTION_START = `GET_PROMOTION_START`;
export const GET_PROMOTION_SUCCESS = `GET_PROMOTION_SUCCESS`;
export const GET_PROMOTION_ERROR = `GET_PROMOTION_ERROR`;

export const CART_PROMOTION_CHANGE = `CART_PROMOTION_CHANGE`;

export const CART_PROMOTION_DETAIL_OPEN = `CART_PROMOTION_DETAIL_OPEN`;
export const CART_PROMOTION_DETAIL_CLOSE = `CART_PROMOTION_DETAIL_CLOSE`;

export const CART_PROMOTION_DETAIL1_OPEN = `CART_PROMOTION_DETAIL1_OPEN`;
export const CART_PROMOTION_DETAIL1_CLOSE = `CART_PROMOTION_DETAIL1_CLOSE`;