import { createTheme } from '@material-ui/core/styles';
const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.MuiDialog-paper': {
          margin: '0px !important'
        },
        '.MuiDialog-paperFullWidth': {
          width: 'calc(100% - 20px) !important'
        },
        '*': {
          fontFamily: 'Manrope !important',
        },
        '.MuiGrid-spacing-xs-1': {
          width: '100% !important'
        },
        '.MuiButton-root': {
          boxShadow: 'none !important'
        },
        '.MuiButton-containedPrimary': {
          color: 'white !important'
        },
        '.swal2-container': {
          zIndex: 9999
        },
        '.MuiOutlinedInput-root': {
          '&:hover fieldset': {
            border: '1px solid #00A79D !important',
          },
        },
        '.Mui-focused': {
          '&>fieldset': {
            border: '1px solid #00A79D !important',
          }
        },
        '.Mui-error': {
          '&>fieldset': {
            borderColor: 'red !important',
          }
          
        },

        '.MuiOutlinedInput-root': {
          '&>fieldset': {
            borderColor:'#E0E0E0 !important'
          },
        },

        '.MuiOutlinedInput-input':{
          padding:'16px 14px !important'
        },
        '.text-error-form': {
          fontSize: '13px !important',
          marginLeft: '0px !important',
          color: 'red !important',
          position: 'absolute !important',
          bottom: '-25px !important'
        },
        '.ant-modal-confirm-btns': {
          '&>.ant-btn-primary': {
            background: '#00a79d !important',
            borderColor: '#00a79d !important',
            height: 40,
            paddingLeft: 30,
            paddingRight: 30,
            borderRadius: 40
          }
        },
        '.ant-modal-confirm-body': {
          '&>.anticon': {
            //color: '#00a79d !important'
          }
        }
        
        
        // '.MuiButton-contained': {
        //   boxShadow: 'none !important'
        // }
      },
    },
  },
  textField: {

  },
  typography: { 
    fontFamily: 'Manrope !important',
    color:'#102647',
    fontSize: 14,
    display4: {
      fontSize: 14,
      color:'#102647',
    },
    display3: {
      fontSize: 14,
      color:'#102647',
    },
    display2: {
      fontSize: 14,
      color:'#102647',
    },
    display1: {
      fontSize: 14,
      color:'#102647',
      
    },
    headline: {
      fontSize: 14,
      color:'#102647',
    },
    title: {
      fontSize: 14,
      color:'#102647',
    },
    subheading: {
      fontSize: 14,
      color:'#102647',
    },
    body2: {
      fontSize: 14,
      color:'#102647',
    },
    body1: {
      fontSize: 14,
      color:'#102647',
      //lineHeight: 1.5,
    },
    caption: {
      fontSize: 14,
      color:'#102647',
    },
    button: {
      fontSize: 14,
      fontFamily: 'Manrope !important',
    },
  },
  palette:  {
    primary: {
      main: "#00A79D",
      //main: "#01a54f",
    },

    error: {
      main: "#f44336",
    },
    warning: {
      main: "#ff9800",
    },
    info: {
      main: "#2196f3",
    },
    success: {
      main: "#4caf50"
    },
  },
});
export default theme;