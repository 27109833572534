import React from 'react';
import PropTypes from 'prop-types';


const DeleteIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.1455 16.0026C15.0559 16.0026 14.9657 16.0026 14.876 16.0026C14.5394 15.9085 14.3046 15.6735 14.0662 15.4349C12.4438 13.8081 10.8174 12.1854 9.19406 10.5596C9.13186 10.4975 9.09356 10.4115 9.03722 10.3268C8.92275 10.4353 8.86282 10.4889 8.80558 10.5461C7.17507 12.1755 5.54367 13.8036 3.91631 15.4358C3.67791 15.6749 3.44267 15.909 3.10557 16.0031C3.01589 16.0031 2.92575 16.0031 2.83607 16.0031C1.88607 15.7631 1.77656 14.7996 2.44399 14.1593C4.13894 12.533 5.77801 10.8491 7.43916 9.18815C7.50045 9.12692 7.55859 9.06298 7.64286 8.97473C7.55363 8.90044 7.47972 8.84821 7.41618 8.78518C5.86003 7.23093 4.30479 5.67577 2.74909 4.12061C2.58505 3.95672 2.4147 3.79824 2.25877 3.62669C1.78782 3.10711 1.98612 2.29351 2.63958 2.06704C3.06275 1.92025 3.41067 2.05668 3.71622 2.3633C5.40757 4.05758 7.10252 5.74826 8.79341 7.44344C8.85515 7.50513 8.8876 7.59608 8.95971 7.71674C9.08409 7.56771 9.13592 7.49432 9.19901 7.43174C10.8809 5.74871 12.5632 4.06704 14.2465 2.38536C14.8968 1.73565 15.7044 1.93691 15.9721 2.81355C15.9761 2.8266 15.9901 2.83651 15.9996 2.84776C15.9996 2.92251 15.9996 2.99725 15.9996 3.07244C15.9252 3.40787 15.7125 3.64876 15.4759 3.88423C13.8319 5.51953 12.1932 7.16069 10.5537 8.80049C10.4915 8.86262 10.4325 8.92701 10.3635 8.9995C10.4307 9.06974 10.4852 9.12962 10.5424 9.1868C12.1721 10.8158 13.8003 12.4457 15.4326 14.0715C15.6715 14.3093 15.9054 14.5447 16 14.8811C16 14.9707 16 15.0607 16 15.1503C15.8819 15.6015 15.5976 15.8856 15.1455 16.0026Z" fill="#3C5264" />
        </svg>
    );
};


DeleteIcon.propTypes = {

};


export default DeleteIcon;
