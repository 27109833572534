import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Badge, IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CartIcon from '../../Core/Icons/CartIcon';
import Addcartbutton from '../../Core/Button/AddCartButton';
import { primaryColor, whiteColor } from '../../../Theme/master';
import _ from 'lodash';
import fm from '../../../pages/config';
import DeleteIcon from '../../Core/Icons/DeleteIcon';
import actions from '../../../pages/public/CartPage/actions';
import { Affix } from 'antd';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#FFFFFF',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
        fontSize: 14,

    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
        marginTop: '100px',

    },
    menuCart: {
        padding: 5,
        '&:first-child': {
            marginTop: 20
        },
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            //backgroundColor: '#F5F5F5'
        }
        
    },
    totalCard: {
        padding: '25px 30px 6px 40px',
        height: '70px',
        background: '#F7FAFB',
        display: 'flex'
    },

    pay: {
        marginRight: '24px',
        paddingLeft: '100px'
    },
    buttonPay: {
        display: 'flex',
        padding: '30px 30px 6px 40px',
        height: '120px'
    },
    cartButton: {
        width: 210,
        border: '1px solid white',
        boxSizing: 'border-box',
        borderRadius: '10px',
        textTransform: 'initial',
        height: 64,
        backgroundColor: whiteColor,
        '&:hover': {
            backgroundColor: whiteColor,
        }
    },
    textProduct: {
        marginLeft: '50px',
        paddingRight: '40px',
        flexGrow: 1
    },

    total: {
        paddingLeft: '190px',
        color: primaryColor,
        fontSize: '18px',
        fontWeight: '500'
    },
    customWidth: {
        maxWidth: 500,
    },
    price: {
        color: primaryColor
    },
    numberMoney: {
        color: '#009688',
        fontSize: '18px',
        fontWeight: '700'
    },
    img: {
        width: '48px',
        height: '48px',

    },
    textTotalProduct: {
        marginTop: '7px',
    },

}));

export default function Cart(props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const carts = useSelector(state => state.checkOut.carts);
    const handleClearCart = id => {

        dispatch(
            actions.clearOneCart(id)
        );
    }
    const totalTemporary = (datas) => {
        var totalPrice = 0;
        if (datas.length > 0) {
            for (let index = 0; index < datas.length; index++) {
                if (datas[index].checked) {
                    totalPrice += datas[index].quantity * datas[index].product_price;
                }
            }
        }
        return totalPrice;
    }
    return (
        <div>
            <Affix
              offsetTop={10}
            >
            <LightTooltip
                placement="top-end"
                enterDelay={0}
                leaveTouchDelay={0}
                title={
                
                !_.isEmpty(carts) ? (
                    <Fragment>
                        {
                            carts.slice(0, 5).map((product, key) => (
                                <div className={classes.menuCart} key={key}>
                                    <img src={process.env.REACT_APP_LINK_IMAGE_PRODUCT + product.product_image} className={classes.img} />

                                    <div className={classes.textProduct}>
                                        <Typography style={{fontWeight: 600}}> {product.product_name}</Typography>
                                        <p >{product.quantity}x <span className={classes.price}>{fm.from(product.product_price)} </span> </p>

                                    </div>
                                    <div className={classes.icon} onClick={() => handleClearCart(product.id_product)}>
                                        <IconButton >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            ))

                        }

                        <div className={classes.buttonPay}>
                            <div className={classes.textTotalProduct}><Typography> {carts.reduce((a, v) => a = a + v.quantity, 0)} sản phẩm </Typography></div>
                            <div className={classes.pay} onClick={() => history.push('/cart')}><Addcartbutton name={'Xem giỏ hàng'} size='xs' /> </div>
                        </div>
                    </Fragment>) :
                    (
                        ""
                    )
            }
                interactive
                classes={{ tooltip: classes.customWidth }}

            >
                <Button
                    className={classes.cartButton}
                    startIcon={
                        <Badge color="secondary" badgeContent={
                            !_.isEmpty(carts) ?
                                carts.reduce((a, v) => a = parseInt(a) + parseInt(v.quantity), 0) : 0

                        }
                            className={classes.cartIcon} overlap="rectangular">
                            <CartIcon />
                        </Badge>
                    }
                    onClick={() => history.push('/cart')}
                >
                    <div style={{ marginLeft: 18 }}>
                        <div style={{ fontWeight: 600 }}> Giỏ hàng </div>
                        <div className={classes.numberMoney}>
                            {!_.isEmpty(carts) ?
                                fm.from(totalTemporary(carts)) : ''
                            }
                        </div>
                    </div>
                </Button>
            </LightTooltip>
            </Affix>


        </div>
    );
}
