import React, { Fragment, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles,Divider } from '@material-ui/core';
//component 
import Footer1 from '../../../components/Modules/Footer/Footer1';
import Footer2 from '../../../components/Modules/Footer/Footer2';
import Footer3 from '../../../components/Modules/Footer/Footer3';
import { container } from '../../../Theme/master';
import { useLocation } from 'react-router-dom';
import {
  handleTracking
} from '../../../../src/utils/tracking';

const useStyles = makeStyles((theme) => ({
  container: {
    ...container
  },
  line:{
  
    height:'0px',
    border: '1px solid #F6F9FA',
  },
  bottomMobie: {
    [theme.breakpoints.down(801)]: {
      height: 54
    }
  }
}));

export default function Footer(props) {
  const classes = useStyles();
  const listCategory = useSelector(state => state.home.categorys);
  const location = useLocation()

  useLayoutEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      setTimeout(() => {
        handleTracking(location)
      }, 1000);
    }
  }, [location]);
  return (

    <Fragment>
      <Footer2 />
      <Divider className={classes.line}/>
      <Footer3 category={listCategory} />
      <div className={classes.bottomMobie} ></div>
    </Fragment>


  )
}
