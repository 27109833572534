import axios from "axios";
import { isAuthenticated } from '../pages/shared/routes/permissionCusChecker';
const baseToken = `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2VvZmZpY2UubWVyYXBncm91cC5jb20vZW9mZmljZS9hcGkiLCJpYXQiOjE2ODM4NzQ4MTcsImV4cCI6MTkzMzUzNDgxNywibmJmIjoxNjgzODc0ODE3LCJqdGkiOiJBSFpqOGF3MHMyQmpra1RqIiwic3ViIjoiMjc2NSIsInBydiI6IjFkMGEwMjBhY2Y1YzRiNmM0OTc5ODlkZjFhYmYwZmJkNGU4YzhkNjMifQ.nPumpINJIdOMD-s5752yMdSqtuKSMjm2GYS0DyvAfck`;
//const baseToken = null;
const asapi = axios.create({
  baseURL: `${process.env.REACT_APP_API_URI}`
});

asapi.interceptors.request.use(
  config => {
    if(isAuthenticated()){
      config.headers["Authorization"] = `Bearer ${isAuthenticated()}`;
    }else {
      config.headers["Authorization"] = `Bearer ${baseToken}`;
    }
    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

export default asapi;