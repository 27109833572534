import { isAuthenticated } from "../pages/shared/routes/permissionCusChecker";
import io from 'socket.io-client';
import getStore, { getHistory } from '../pages/configureStore';

let socket = null;
const onConnected = () => {
  console.log("socket: connected");
};


const onDisconnect = () => {
  console.log(`socket: disconent`);
};

let data = window.localStorage.getItem("authcus");
let user = data ? JSON.parse(data).user : {};


export const configSocket = (user) => {
  if (socket && socket.disconnected) {
    socket.connect();
  }
  if (socket) return;


  socket = io.connect(`${process.env.REACT_APP_URI_SOCKET}store`, {
    query: `id=${user.id_customer_oa}&full_name=${user.customer_name}&token=${isAuthenticated()}`,
    multiplex: false
  });


  socket.on("connect", onConnected);
  socket.on("disconnect", onDisconnect);

  socket.on('send-logout-request', () => {
    socketDisconnect();
    getHistory().push('/login');
    window.localStorage.removeItem("authcus");
    window.localStorage.removeItem("carts");
  });



  // admin gửi yêu cầu khóa hệ thống
  socket.on('admin-lock-system', () => {
    socketDisconnect();
    getHistory().push('/locked');
  });




  return socket;
}


export const socketDisconnect = () => {
  if(socket){
    socket.disconnect();
    socket = null;
  }
  
};

export default function getSocket() {
  return socket;
}


export const userId = () => {
  return user.id_user;
};