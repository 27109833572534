import React from 'react'
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    listName: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: 3
    },
    text: {
        color: '#102647',
        fontWeight: 'bold',
        marginLeft: 20,
        '&:hover': {
            color: theme.palette.primary.main
        }
    }
}));

const Category = ({
    setOpen
}) => {
    const classes = useStyles();
    const { categorys } = useSelector(state => state.home);
    return (
        <div>
            {
            categorys.filter(val => val.menu_vertical === 1).map((val, i) => (
                <div className={classes.listName} key={i}>
                    <div>{val.icon ? parse(val.icon) : ''}</div>
                    <Link 
                        onClick={() => setOpen(false)}
                        className={classes.text} to={`/search?id_category=${val.id_category}`} >
                            {val.category_name}
                        </Link>

                </div>
            ))
            }
        </div>
    )
}

export default Category;
