import produce from 'immer';
import * as constants from './constants';
//import _ from 'lodash';

const initialState = {
    isloadingDetail: false,
    details: {},
    products: []
}

const reducer = (state = initialState, { type, payload }) =>
    produce(state, draft => {

        switch (type) {

            case constants.GET_PRODUCT_DETAIL_START:
                draft.isloadingDetail = true;
                draft.details = {};
                draft.products = [];
            break;

            case constants.GET_PRODUCT_DETAIL_SUCCESS:
                draft.isloadingDetail = false;
                draft.details = payload.details;
                draft.products = payload.products;
            break;

            case constants.GET_PRODUCT_DETAIL_ERROR:
                draft.isloadingDetail = false;
                draft.details = {};
                draft.products = [];
            break;
            

            case `RESET_STATE`:
                draft.details = {};
                draft.products = [];
                //draft.isloadingDetail = false;
                break;

            default:
                break;
        }
    });

export default reducer;