import React, { useState } from 'react'
import { Badge, Button, Fab, IconButton, makeStyles, SvgIcon } from '@material-ui/core';

import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import DrawerMobie from './DrawerMobie';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        bottom: 0,
        zIndex: 10,
        background: 'white',
        height: 54,
        width: '100%',
        boxShadow: '0px 0px 10px rgba(32, 37, 40, 0.15)'
    },
    padding: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 13,
        paddingRight: 13,
        height: 54,
    },
    groupIcon: {
        textAlign: 'center',
        cursor: 'pointer'
    },
    textName: {
        fontSize: 12
    },
    icon: {
        '&>svg': {

        }
    },
    cartIcon: {
        '&>.MuiBadge-badge': {
            right: '-0px',
        }
    }
}));

export const IconHome = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2211_13957)">
                <path d="M8.991 17.9976C7.70586 17.9976 6.42112 17.9972 5.13598 17.9976C3.36771 17.9984 1.93076 16.564 1.92996 14.7956C1.92955 13.5113 1.92795 12.2269 1.93116 10.9422C1.93196 10.5982 2.17092 10.3309 2.49461 10.2916C2.8167 10.2523 3.12393 10.4662 3.19743 10.7893C3.2163 10.8727 3.21469 10.9618 3.21469 11.0485C3.2155 12.2927 3.2151 13.5369 3.2155 14.7815C3.2159 15.8644 4.07012 16.7133 5.15325 16.7129C7.7167 16.7117 10.2798 16.7117 12.8432 16.7129C13.9279 16.7133 14.7834 15.866 14.7842 14.7844C14.7846 13.5133 14.7826 12.2422 14.7854 10.9711C14.7866 10.4718 15.2396 10.1491 15.6741 10.3393C15.9468 10.4589 16.0709 10.6813 16.0705 10.9791C16.0689 12.2502 16.0705 13.5213 16.0697 14.7924C16.0693 15.5718 15.8179 16.2642 15.3131 16.8558C14.6733 17.6051 13.8524 17.9892 12.8665 17.9952C11.5745 18.004 10.2826 17.998 8.991 17.9976Z" fill="#3C5264" />
                <path d="M9.01553 1.54602C8.27497 2.28532 7.56453 2.99371 6.85529 3.70291C4.9577 5.60012 3.05971 7.49732 1.16453 9.39694C0.952077 9.60966 0.716334 9.7116 0.422358 9.60524C0.00629424 9.45514 -0.133465 8.9398 0.146053 8.59704C0.188222 8.54526 0.238021 8.49991 0.285009 8.45255C3.00629 5.73216 5.72758 3.01217 8.44927 0.291786C8.83842 -0.0971281 9.16212 -0.0975294 9.55208 0.292187C12.2782 3.01699 15.0035 5.7426 17.732 8.46539C17.9424 8.6753 18.0637 8.90207 17.9661 9.20108C17.8224 9.64056 17.2926 9.79027 16.9392 9.49287C16.883 9.44551 16.8328 9.39132 16.7806 9.33915C14.2493 6.80739 11.7183 4.27524 9.18782 1.74269C9.13079 1.6865 9.0822 1.62268 9.01553 1.54602Z" fill="#3C5264" />
            </g>
            <defs>
                <clipPath id="clip0_2211_13957">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const IconHomeActive = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2211_9785)">
                <path d="M8.99075 17.9975C7.70561 17.9975 6.42088 17.9971 5.13574 17.9975C3.36746 17.9983 1.93052 16.5638 1.92971 14.7955C1.92931 13.5111 1.9277 12.2268 1.93092 10.9421C1.93172 10.5981 2.17068 10.3308 2.49437 10.2915C2.81646 10.2521 3.12369 10.4661 3.19718 10.7891C3.21606 10.8726 3.21445 10.9617 3.21445 11.0484C3.21525 12.2926 3.21485 13.5368 3.21525 14.7814C3.21566 15.8643 4.06987 16.7131 5.153 16.7127C7.71646 16.7115 10.2795 16.7115 12.843 16.7127C13.9277 16.7131 14.7831 15.8659 14.7839 14.7842C14.7843 13.5131 14.7823 12.2421 14.7851 10.971C14.7863 10.4717 15.2393 10.149 15.6739 10.3392C15.9466 10.4588 16.0707 10.6812 16.0703 10.979C16.0687 12.2501 16.0703 13.5212 16.0695 14.7923C16.0691 15.5717 15.8177 16.264 15.3128 16.8556C14.6731 17.605 13.8522 17.9891 12.8663 17.9951C11.5743 18.0039 10.2823 17.9979 8.99075 17.9975Z" fill="url(#paint0_linear_2211_9785)" />
                <path d="M9.01529 1.54602C8.27472 2.28532 7.56428 2.99371 6.85505 3.70291C4.95746 5.60012 3.05946 7.49732 1.16428 9.39694C0.951833 9.60966 0.71609 9.7116 0.422114 9.60524C0.0060501 9.45514 -0.133709 8.9398 0.145809 8.59704C0.187978 8.54526 0.237777 8.49991 0.284765 8.45255C3.00605 5.73216 5.72733 3.01217 8.44902 0.291786C8.83818 -0.0971281 9.16187 -0.0975294 9.55183 0.292187C12.2779 3.01699 15.0032 5.7426 17.7318 8.46539C17.9422 8.6753 18.0635 8.90207 17.9659 9.20108C17.8221 9.64056 17.2924 9.79027 16.939 9.49287C16.8828 9.44551 16.8326 9.39132 16.7803 9.33915C14.249 6.80739 11.7181 4.27524 9.18758 1.74269C9.13055 1.6865 9.08195 1.62268 9.01529 1.54602Z" fill="url(#paint1_linear_2211_9785)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_2211_9785" x1="1.92896" y1="14.1419" x2="17.2487" y2="14.1419" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00CFD0" />
                    <stop offset="1" stopColor="#8DF6DA" />
                </linearGradient>
                <linearGradient id="paint1_linear_2211_9785" x1="0.000244208" y1="4.82388" x2="19.5001" y2="4.82388" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00CFD0" />
                    <stop offset="1" stopColor="#8DF6DA" />
                </linearGradient>
                <clipPath id="clip0_2211_9785">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export const IconCategory = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2211_14004)">
                <path d="M8.09972 4.69852C8.09972 5.60435 8.10009 6.51017 8.09934 7.416C8.09897 7.86273 7.86317 8.09996 7.41894 8.09996C5.63866 8.10071 3.85801 8.10221 2.07772 8.09959C0.868753 8.09809 0.00279187 7.22899 0.0009175 6.0181C-0.000956875 4.70639 -0.000956875 3.39431 0.0009175 2.0826C0.00316675 0.871705 0.869128 0.00410407 2.07885 0.00185543C3.39691 -0.000393212 4.71497 -0.00114276 6.03303 0.00185543C7.23113 0.00447885 8.09597 0.873579 8.09934 2.07473C8.10196 2.94945 8.09972 3.8238 8.09972 4.69852ZM6.90012 6.90031C6.90012 6.81786 6.90012 6.7624 6.90012 6.70693C6.90012 5.17073 6.90049 3.63454 6.89974 2.09834C6.89937 1.52793 6.5751 1.20188 6.00791 1.20151C4.7026 1.20076 3.39766 1.20076 2.09234 1.20151C1.52441 1.20188 1.20127 1.52718 1.20089 2.09834C1.20014 3.39093 1.20052 4.68353 1.20089 5.97612C1.20089 6.58438 1.51541 6.89994 2.12196 6.89994C3.64583 6.90031 5.16969 6.89994 6.69318 6.89994C6.75466 6.90031 6.81577 6.90031 6.90012 6.90031Z" fill="#3C5264" />
                <path d="M9.90006 4.69294C9.90006 3.82459 9.89894 2.95623 9.90044 2.08826C9.90269 0.866871 10.7671 0.00339278 11.9911 0.00189368C13.2964 1.98142e-05 14.6021 1.98142e-05 15.9078 0.00189368C17.1329 0.00339278 17.997 0.865372 17.9985 2.08788C18.0004 3.39959 18.0007 4.71167 17.9985 6.02338C17.9962 7.23278 17.1288 8.09813 15.9164 8.09963C14.1362 8.10188 12.3555 8.10075 10.5752 8.1C10.143 8.1 9.90044 7.85865 9.90006 7.42916C9.89931 6.51696 9.90006 5.60513 9.90006 4.69294ZM11.0997 6.90035C11.178 6.90035 11.2331 6.90035 11.2882 6.90035C12.8308 6.90035 14.3731 6.9011 15.9157 6.89998C16.4701 6.8996 16.7981 6.56943 16.7985 6.01289C16.7993 4.70793 16.7993 3.40259 16.7985 2.09763C16.7981 1.52797 16.4735 1.20192 15.9059 1.20154C14.6006 1.20079 13.2957 1.20079 11.9904 1.20154C11.4232 1.20192 11.1 1.52722 11.1 2.09875C11.0993 3.47267 11.0997 4.84622 11.0997 6.22014C11.0997 6.43826 11.0997 6.656 11.0997 6.90035Z" fill="#3C5264" />
                <path d="M13.3016 9.8997C14.1698 9.8997 15.0384 9.89857 15.9066 9.90007C17.1321 9.90232 17.9969 10.7635 17.9984 11.9853C18.0003 13.297 18.0007 14.6087 17.9984 15.9208C17.9962 17.131 17.1298 17.9963 15.9175 17.9982C14.6058 18.0004 13.2938 18.0004 11.9821 17.9982C10.7712 17.9963 9.90188 17.1298 9.90038 15.9216C9.89813 14.1414 9.89963 12.3608 9.90001 10.5807C9.90001 10.1381 10.1396 9.90045 10.5842 9.90007C11.4899 9.89932 12.3959 9.8997 13.3016 9.8997ZM11.0996 11.0997C11.0996 11.1852 11.0996 11.241 11.0996 11.2972C11.0996 12.8334 11.0992 14.3696 11.1 15.9054C11.1004 16.4736 11.4257 16.7982 11.9956 16.7985C13.2945 16.7993 14.5934 16.7993 15.8924 16.7985C16.4749 16.7982 16.7988 16.4766 16.7992 15.8972C16.8 14.5982 16.8 13.2996 16.7992 12.0007C16.7988 11.4209 16.4757 11.0997 15.8928 11.0997C14.3629 11.0993 12.8327 11.0997 11.3028 11.0997C11.2417 11.0997 11.1806 11.0997 11.0996 11.0997Z" fill="#3C5264" />
                <path d="M4.70245 9.89962C5.60815 9.89962 6.51422 9.89924 7.41992 9.89999C7.86265 9.90037 8.09995 10.1391 8.09995 10.5843C8.1007 12.3645 8.1022 14.145 8.09957 15.9252C8.0977 17.1252 7.23061 17.9947 6.03288 17.9977C4.71482 18.0007 3.39676 18.0003 2.0787 17.9977C0.869355 17.9955 0.00376844 17.1271 0.00189406 15.9162C1.96874e-05 14.6045 1.96874e-05 13.2928 0.00189406 11.9807C0.00376844 10.7695 0.869355 9.90299 2.07908 9.89999C2.95329 9.89812 3.82787 9.89962 4.70245 9.89962ZM6.90035 11.0996C6.81412 11.0996 6.75827 11.0996 6.70204 11.0996C5.1658 11.0996 3.62956 11.0993 2.09332 11.1C1.52501 11.1004 1.20149 11.4257 1.20112 11.9961C1.20037 13.2951 1.20074 14.5937 1.20112 15.8926C1.20149 16.4754 1.52239 16.7984 2.10269 16.7984C3.40164 16.7992 4.70058 16.7992 5.99952 16.7984C6.5787 16.7981 6.89997 16.4743 6.90035 15.8915C6.90072 14.3617 6.90035 12.8318 6.90035 11.3016C6.90035 11.2406 6.90035 11.1798 6.90035 11.0996Z" fill="#3C5264" />
            </g>
            <defs>
                <clipPath id="clip0_2211_14004">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}



export const IconCategoryActive = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2211_13964)">
                <path d="M8.09972 4.69852C8.09972 5.60435 8.10009 6.51017 8.09934 7.416C8.09897 7.86273 7.86317 8.09996 7.41894 8.09996C5.63866 8.10071 3.85801 8.10221 2.07772 8.09959C0.868753 8.09809 0.00279187 7.22899 0.0009175 6.0181C-0.000956875 4.70639 -0.000956875 3.39431 0.0009175 2.0826C0.00316675 0.871705 0.869128 0.00410407 2.07885 0.00185543C3.39691 -0.000393212 4.71497 -0.00114276 6.03303 0.00185543C7.23113 0.00447884 8.09597 0.873579 8.09934 2.07473C8.10196 2.94945 8.09972 3.8238 8.09972 4.69852ZM6.90012 6.90031C6.90012 6.81786 6.90012 6.7624 6.90012 6.70693C6.90012 5.17073 6.90049 3.63454 6.89974 2.09834C6.89937 1.52793 6.5751 1.20188 6.00791 1.20151C4.7026 1.20076 3.39766 1.20076 2.09234 1.20151C1.52441 1.20188 1.20127 1.52718 1.20089 2.09834C1.20014 3.39093 1.20052 4.68353 1.20089 5.97612C1.20089 6.58438 1.51541 6.89994 2.12196 6.89994C3.64583 6.90031 5.16969 6.89994 6.69318 6.89994C6.75466 6.90031 6.81577 6.90031 6.90012 6.90031Z" fill="url(#paint0_linear_2211_13964)" />
                <path d="M9.90031 4.69294C9.90031 3.82459 9.89918 2.95623 9.90068 2.08826C9.90293 0.866871 10.7674 0.00339278 11.9914 0.00189368C13.2967 1.98142e-05 14.6024 1.98142e-05 15.9081 0.00189368C17.1331 0.00339278 17.9972 0.865372 17.9987 2.08788C18.0006 3.39959 18.001 4.71167 17.9987 6.02338C17.9965 7.23278 17.129 8.09813 15.9167 8.09963C14.1364 8.10188 12.3557 8.10075 10.5755 8.1C10.1432 8.1 9.90068 7.85865 9.90031 7.42916C9.89956 6.51696 9.90031 5.60513 9.90031 4.69294ZM11.0999 6.90035C11.1783 6.90035 11.2334 6.90035 11.2885 6.90035C12.8311 6.90035 14.3733 6.9011 15.9159 6.89998C16.4704 6.8996 16.7984 6.56943 16.7988 6.01289C16.7995 4.70793 16.7995 3.40259 16.7988 2.09763C16.7984 1.52797 16.4737 1.20192 15.9062 1.20154C14.6009 1.20079 13.2959 1.20079 11.9906 1.20154C11.4234 1.20192 11.1003 1.52722 11.1003 2.09875C11.0995 3.47267 11.0999 4.84622 11.0999 6.22014C11.0999 6.43826 11.0999 6.656 11.0999 6.90035Z" fill="url(#paint1_linear_2211_13964)" />
                <path d="M13.3019 9.8997C14.1701 9.8997 15.0387 9.89857 15.9069 9.90007C17.1323 9.90232 17.9972 10.7635 17.9987 11.9853C18.0006 13.297 18.0009 14.6087 17.9987 15.9208C17.9964 17.131 17.1301 17.9963 15.9177 17.9982C14.6061 18.0004 13.294 18.0004 11.9823 17.9982C10.7715 17.9963 9.90213 17.1298 9.90063 15.9216C9.89838 14.1414 9.89988 12.3608 9.90025 10.5807C9.90025 10.1381 10.1398 9.90045 10.5844 9.90007C11.4901 9.89932 12.3962 9.8997 13.3019 9.8997ZM11.0999 11.0997C11.0999 11.1852 11.0999 11.241 11.0999 11.2972C11.0999 12.8334 11.0995 14.3696 11.1002 15.9054C11.1006 16.4736 11.426 16.7982 11.9958 16.7985C13.2947 16.7993 14.5937 16.7993 15.8926 16.7985C16.4752 16.7982 16.7991 16.4766 16.7995 15.8972C16.8002 14.5982 16.8002 13.2996 16.7995 12.0007C16.7991 11.4209 16.4759 11.0997 15.893 11.0997C14.3631 11.0993 12.8329 11.0997 11.303 11.0997C11.2419 11.0997 11.1808 11.0997 11.0999 11.0997Z" fill="url(#paint2_linear_2211_13964)" />
                <path d="M4.70245 9.89962C5.60815 9.89962 6.51422 9.89924 7.41992 9.89999C7.86265 9.90037 8.09995 10.1391 8.09995 10.5843C8.1007 12.3645 8.1022 14.145 8.09957 15.9252C8.0977 17.1252 7.23061 17.9947 6.03288 17.9977C4.71482 18.0007 3.39676 18.0003 2.0787 17.9977C0.869355 17.9955 0.00376844 17.1271 0.00189406 15.9162C1.96874e-05 14.6045 1.96874e-05 13.2928 0.00189406 11.9807C0.00376844 10.7695 0.869355 9.90299 2.07908 9.89999C2.95329 9.89812 3.82787 9.89962 4.70245 9.89962ZM6.90035 11.0996C6.81413 11.0996 6.75827 11.0996 6.70204 11.0996C5.1658 11.0996 3.62956 11.0993 2.09332 11.1C1.52501 11.1004 1.20149 11.4257 1.20112 11.9961C1.20037 13.2951 1.20074 14.5937 1.20112 15.8926C1.20149 16.4754 1.52239 16.7984 2.10269 16.7984C3.40164 16.7992 4.70058 16.7992 5.99952 16.7984C6.5787 16.7981 6.89997 16.4743 6.90035 15.8915C6.90072 14.3617 6.90035 12.8318 6.90035 11.3016C6.90035 11.2406 6.90035 11.1798 6.90035 11.0996Z" fill="url(#paint3_linear_2211_13964)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_2211_13964" x1="-0.000488251" y1="4.05048" x2="8.7757" y2="4.05048" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00CFD0" />
                    <stop offset="1" stopColor="#8DF6DA" />
                </linearGradient>
                <linearGradient id="paint1_linear_2211_13964" x1="9.8999" y1="4.05072" x2="18.6753" y2="4.05072" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00CFD0" />
                    <stop offset="1" stopColor="#8DF6DA" />
                </linearGradient>
                <linearGradient id="paint2_linear_2211_13964" x1="9.89941" y1="13.9496" x2="18.6753" y2="13.9496" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00CFD0" />
                    <stop offset="1" stopColor="#8DF6DA" />
                </linearGradient>
                <linearGradient id="paint3_linear_2211_13964" x1="0.000488311" y1="13.9494" x2="8.7761" y2="13.9494" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00CFD0" />
                    <stop offset="1" stopColor="#8DF6DA" />
                </linearGradient>
                <clipPath id="clip0_2211_13964">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>


    )
}

export const IconCart = () => {
    return (
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.35657 1.3293C4.3162 1.18619 4.12364 1.04052 3.97498 1.04052H0.780931C0.493608 1.04052 0.260672 0.807651 0.260672 0.52026C0.260672 0.232868 0.493608 0 0.780931 0H3.97498C4.58472 0 5.19218 0.459632 5.3579 1.04642L6.22077 4.1002C6.31941 4.07273 6.42332 4.05685 6.5314 4.05685H16.851C17.1795 4.05685 17.4913 4.19309 17.7064 4.4306C17.9216 4.66812 18.0266 4.99179 17.9942 5.31851L17.513 10.197C17.4488 10.8472 16.8868 11.3565 16.2334 11.3565H8.22384C7.61153 11.3565 7.00671 10.8935 6.84688 10.3025L5.84341 6.59169L4.35657 1.3293ZM7.8514 10.0309C7.88955 10.1721 8.07761 10.316 8.22391 10.316H16.2335C16.3477 10.316 16.4663 10.2085 16.4775 10.0948L16.9589 5.21633C16.9625 5.18026 16.9544 5.15016 16.9355 5.12935C16.9165 5.10847 16.8874 5.09744 16.8511 5.09744H6.5314C6.52633 5.09744 6.52189 5.09764 6.51801 5.09792C6.5187 5.10181 6.51974 5.10611 6.52106 5.11096L7.8514 10.0309Z" fill="#3C5264" />
            <path d="M14.7147 11.9495C15.6071 11.9495 16.333 12.6754 16.333 13.5677C16.333 14.46 15.6071 15.1859 14.7147 15.1859C13.8224 15.1859 13.0965 14.46 13.0965 13.5677C13.0965 12.6754 13.8224 11.9495 14.7147 11.9495ZM14.7147 14.1454C15.0333 14.1454 15.2925 13.8862 15.2925 13.5677C15.2925 13.2491 15.0333 12.99 14.7147 12.99C14.3962 12.99 14.137 13.2491 14.137 13.5677C14.137 13.8862 14.3962 14.1454 14.7147 14.1454Z" fill="#3C5264" />
            <path d="M9.58393 11.9495C10.4762 11.9495 11.2021 12.6754 11.2021 13.5677C11.2021 14.46 10.4762 15.1859 9.58393 15.1859C8.69165 15.1859 7.96572 14.46 7.96572 13.5677C7.96572 12.6754 8.69158 11.9495 9.58393 11.9495ZM9.58393 14.1454C9.90247 14.1454 10.1616 13.8862 10.1616 13.5677C10.1616 13.2491 9.90247 12.99 9.58393 12.99C9.26539 12.99 9.00624 13.2491 9.00624 13.5677C9.00624 13.8862 9.26533 14.1454 9.58393 14.1454Z" fill="#3C5264" />
        </svg>
    )
}

export const IconCartActive = () => {
    return (
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.35657 1.3293C4.3162 1.18619 4.12364 1.04052 3.97498 1.04052H0.780931C0.493608 1.04052 0.260672 0.807651 0.260672 0.52026C0.260672 0.232868 0.493608 0 0.780931 0H3.97498C4.58472 0 5.19218 0.459632 5.3579 1.04642L6.22077 4.1002C6.31941 4.07273 6.42332 4.05685 6.5314 4.05685H16.851C17.1795 4.05685 17.4913 4.19309 17.7064 4.4306C17.9216 4.66812 18.0266 4.99179 17.9942 5.31851L17.513 10.197C17.4488 10.8472 16.8868 11.3565 16.2334 11.3565H8.22384C7.61153 11.3565 7.00671 10.8935 6.84688 10.3025L5.84341 6.59169L4.35657 1.3293ZM7.8514 10.0309C7.88955 10.1721 8.07761 10.316 8.22391 10.316H16.2335C16.3477 10.316 16.4663 10.2085 16.4775 10.0948L16.9589 5.21633C16.9625 5.18026 16.9544 5.15016 16.9355 5.12935C16.9165 5.10847 16.8874 5.09744 16.8511 5.09744H6.5314C6.52633 5.09744 6.52189 5.09764 6.51801 5.09792C6.5187 5.10181 6.51974 5.10611 6.52106 5.11096L7.8514 10.0309Z" fill="url(#paint0_linear_2211_10202)" />
            <path d="M14.715 11.9495C15.6073 11.9495 16.3333 12.6754 16.3333 13.5677C16.3333 14.46 15.6073 15.1859 14.715 15.1859C13.8227 15.1859 13.0967 14.46 13.0967 13.5677C13.0967 12.6754 13.8227 11.9495 14.715 11.9495ZM14.715 14.1454C15.0336 14.1454 15.2927 13.8862 15.2927 13.5677C15.2927 13.2491 15.0336 12.99 14.715 12.99C14.3964 12.99 14.1373 13.2491 14.1373 13.5677C14.1373 13.8862 14.3964 14.1454 14.715 14.1454Z" fill="url(#paint1_linear_2211_10202)" />
            <path d="M9.58381 11.9495C10.4761 11.9495 11.202 12.6754 11.202 13.5677C11.202 14.46 10.4761 15.1859 9.58381 15.1859C8.69153 15.1859 7.96559 14.46 7.96559 13.5677C7.96559 12.6754 8.69146 11.9495 9.58381 11.9495ZM9.58381 14.1454C9.90235 14.1454 10.1615 13.8862 10.1615 13.5677C10.1615 13.2491 9.90235 12.99 9.58381 12.99C9.26527 12.99 9.00611 13.2491 9.00611 13.5677C9.00611 13.8862 9.2652 14.1454 9.58381 14.1454Z" fill="url(#paint2_linear_2211_10202)" />
            <defs>
                <linearGradient id="paint0_linear_2211_10202" x1="18" y1="5.67825" x2="-1.21761" y2="5.67825" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00CFD0" />
                    <stop offset="1" stopColor="#8DF6DA" />
                </linearGradient>
                <linearGradient id="paint1_linear_2211_10202" x1="16.3333" y1="13.5677" x2="12.827" y2="13.5677" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00CFD0" />
                    <stop offset="1" stopColor="#8DF6DA" />
                </linearGradient>
                <linearGradient id="paint2_linear_2211_10202" x1="11.202" y1="13.5677" x2="7.69589" y2="13.5677" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00CFD0" />
                    <stop offset="1" stopColor="#8DF6DA" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export const IconSearch = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2462 16.3951L12.929 11.9395C14.039 10.6301 14.6472 8.98262 14.6472 7.26749C14.6472 3.26026 11.3618 0 7.32361 0C3.28544 0 0 3.26026 0 7.26749C0 11.2747 3.28544 14.535 7.32361 14.535C8.8396 14.535 10.2843 14.0812 11.5194 13.2199L15.8693 17.7093C16.0511 17.8967 16.2957 18 16.5577 18C16.8058 18 17.0411 17.9062 17.2197 17.7355C17.5993 17.3731 17.6114 16.7721 17.2462 16.3951ZM7.32361 1.89587C10.3085 1.89587 12.7367 4.30552 12.7367 7.26749C12.7367 10.2295 10.3085 12.6391 7.32361 12.6391C4.33876 12.6391 1.91051 10.2295 1.91051 7.26749C1.91051 4.30552 4.33876 1.89587 7.32361 1.89587Z" fill="#3C5264" />
        </svg>
    )
}

export const IconSearchActive = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2462 16.3951L12.929 11.9395C14.039 10.6301 14.6472 8.98262 14.6472 7.26749C14.6472 3.26026 11.3618 0 7.32361 0C3.28544 0 0 3.26026 0 7.26749C0 11.2747 3.28544 14.535 7.32361 14.535C8.8396 14.535 10.2843 14.0812 11.5194 13.2199L15.8693 17.7093C16.0511 17.8967 16.2957 18 16.5577 18C16.8058 18 17.0411 17.9062 17.2197 17.7355C17.5993 17.3731 17.6114 16.7721 17.2462 16.3951ZM7.32361 1.89587C10.3085 1.89587 12.7367 4.30552 12.7367 7.26749C12.7367 10.2295 10.3085 12.6391 7.32361 12.6391C4.33876 12.6391 1.91051 10.2295 1.91051 7.26749C1.91051 4.30552 4.33876 1.89587 7.32361 1.89587Z" fill="url(#paint0_linear_2211_13842)" />
            <defs>
                <linearGradient id="paint0_linear_2211_13842" x1="6.52388e-08" y1="9" x2="18.9718" y2="9" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00CFD0" />
                    <stop offset="1" stopColor="#8DF6DA" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export const IconAccount = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2211_13954)">
                <path d="M8.40026 0C8.79873 0 9.1972 0 9.59567 0C9.64641 0.0112742 9.69644 0.026424 9.74752 0.033118C10.066 0.0753964 10.3884 0.0972402 10.703 0.159601C12.9152 0.6 14.7191 1.71368 16.1115 3.48514C17.0278 4.65097 17.6131 5.97005 17.8611 7.43429C17.9157 7.75596 17.9541 8.08045 17.9999 8.40388C17.9999 8.80235 17.9999 9.20082 17.9999 9.5993C17.9728 9.81914 17.9495 10.0393 17.9175 10.2585C17.6627 11.9982 16.9873 13.5555 15.8183 14.8711C13.6657 17.294 10.9556 18.3062 7.74494 17.9144C6.00625 17.7023 4.45463 17.0005 3.14471 15.8343C0.577358 13.5491 -0.42499 10.6809 0.163383 7.30288C0.553753 5.06107 1.72627 3.25297 3.52204 1.85743C4.67906 0.958309 5.9844 0.383676 7.43102 0.139166C7.75269 0.0845567 8.07718 0.0458015 8.40026 0ZM6.84899 9.88221C4.63326 8.28021 4.88975 5.45003 6.32263 4.05484C7.82492 2.59201 10.1992 2.59871 11.6849 4.06577C13.1171 5.47998 13.344 8.29219 11.1501 9.87939C13.1312 10.682 14.414 12.1113 15.0031 14.1668C16.8351 12.1381 17.5905 8.80446 16.2725 5.8101C14.9312 2.76359 11.6751 0.653905 7.9743 1.1246C4.80167 1.52836 1.9958 3.89348 1.23725 7.3064C0.967375 8.52155 0.998027 9.736 1.29996 10.9413C1.60225 12.148 2.1635 13.2208 2.98969 14.1809C3.58335 12.0994 4.8753 10.6855 6.84899 9.88221ZM9.12956 16.9455C9.3184 16.9307 9.62879 16.9156 9.93637 16.8803C11.3449 16.7183 12.6041 16.1901 13.7552 15.3647C14.0592 15.1466 14.0877 14.9348 14.0106 14.6075C13.7002 13.2895 13.0012 12.2375 11.8928 11.4666C10.8732 10.7574 9.72744 10.4794 8.49785 10.5942C7.4201 10.695 6.4625 11.1129 5.64688 11.8277C4.68681 12.6694 4.10231 13.7225 3.93003 14.9937C3.92263 15.0472 3.95821 15.129 4.00155 15.1635C5.46367 16.3385 7.13613 16.9251 9.12956 16.9455ZM11.7293 6.73811C11.7216 5.23652 10.4923 4.00834 8.99779 4.00869C7.50255 4.00904 6.2733 5.23793 6.26696 6.73881C6.26062 8.24392 7.4962 9.47422 9.0073 9.46718C10.5163 9.46048 11.7371 8.23652 11.7293 6.73811Z" fill="#3C5264" />
            </g>
            <defs>
                <clipPath id="clip0_2211_13954">
                    <rect width="18" height="17.9965" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const IconAccountActive = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2211_13994)">
                <path d="M8.40026 0C8.79873 0 9.1972 0 9.59567 0C9.64641 0.0112742 9.69644 0.026424 9.74752 0.033118C10.066 0.0753964 10.3884 0.0972402 10.703 0.159601C12.9152 0.6 14.7191 1.71368 16.1115 3.48514C17.0278 4.65097 17.6131 5.97005 17.8611 7.43429C17.9157 7.75596 17.9541 8.08045 17.9999 8.40388C17.9999 8.80235 17.9999 9.20082 17.9999 9.5993C17.9728 9.81914 17.9495 10.0393 17.9175 10.2585C17.6627 11.9982 16.9873 13.5555 15.8183 14.8711C13.6657 17.294 10.9556 18.3062 7.74494 17.9144C6.00625 17.7023 4.45463 17.0005 3.14471 15.8343C0.577358 13.5491 -0.42499 10.6809 0.163383 7.30288C0.553753 5.06107 1.72627 3.25297 3.52204 1.85743C4.67906 0.958309 5.9844 0.383676 7.43102 0.139166C7.75269 0.0845567 8.07718 0.0458015 8.40026 0ZM6.84899 9.88221C4.63326 8.28021 4.88975 5.45003 6.32263 4.05484C7.82492 2.59201 10.1992 2.59871 11.6849 4.06577C13.1171 5.47998 13.344 8.29219 11.1501 9.87939C13.1312 10.682 14.414 12.1113 15.0031 14.1668C16.8351 12.1381 17.5905 8.80446 16.2725 5.8101C14.9312 2.76359 11.6751 0.653905 7.9743 1.1246C4.80167 1.52836 1.9958 3.89348 1.23725 7.3064C0.967375 8.52155 0.998027 9.736 1.29996 10.9413C1.60225 12.148 2.1635 13.2208 2.98969 14.1809C3.58335 12.0994 4.8753 10.6855 6.84899 9.88221ZM9.12956 16.9455C9.3184 16.9307 9.62879 16.9156 9.93637 16.8803C11.3449 16.7183 12.6041 16.1901 13.7552 15.3647C14.0592 15.1466 14.0877 14.9348 14.0106 14.6075C13.7002 13.2895 13.0012 12.2375 11.8928 11.4666C10.8732 10.7574 9.72744 10.4794 8.49785 10.5942C7.4201 10.695 6.4625 11.1129 5.64688 11.8277C4.68681 12.6694 4.10231 13.7225 3.93003 14.9937C3.92263 15.0472 3.95821 15.129 4.00155 15.1635C5.46367 16.3385 7.13613 16.9251 9.12956 16.9455ZM11.7293 6.73811C11.7216 5.23652 10.4923 4.00834 8.99779 4.00869C7.50255 4.00904 6.2733 5.23793 6.26696 6.73881C6.26062 8.24392 7.4962 9.47422 9.0073 9.46718C10.5163 9.46048 11.7371 8.23652 11.7293 6.73811Z" fill="url(#paint0_linear_2211_13994)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_2211_13994" x1="6.70548e-08" y1="8.99818" x2="19.4999" y2="8.99818" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00CFD0" />
                    <stop offset="1" stopColor="#8DF6DA" />
                </linearGradient>
                <clipPath id="clip0_2211_13994">
                    <rect width="18" height="17.9965" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

const BottomMobieNav = () => {
    const carts = useSelector(state => state.checkOut.carts);
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const search = queryString.parse(location.search);
    const [drawer, setDrawer] = useState('');
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('Tìm kiếm');

    return (
        <div className={classes.root}>
            <DrawerMobie 
                drawer={drawer} 
                open={open} 
                setOpen={setOpen} 
                setDrawer={setDrawer} 
                title={title} 
            />
            <div className={classes.padding} >
                <div className={classes.groupIcon} onClick={() => {
                    setOpen(false);
                    setDrawer('');
                    history.push('/');
                }}>
                    <div className={classes.icon}>
                    <IconButton 
                        size='small' 
                        color="primary">
                        {drawer === '' && location.pathname === '/'  ? <IconHomeActive /> : <IconHome />}
                    </IconButton>
                        
                    </div>
                    <div className={classes.textName}>
                        Trang chủ
                    </div>
                </div>
                <div className={classes.groupIcon}
                    onClick={() => {
                        setOpen(true);
                        setTitle('Danh mục');
                        setDrawer('category');
                    }}
                >
                    <div className={classes.icon}>
                    <IconButton
                        
                        size='small' 
                        color="primary" 
                    >
                        {drawer === 'category' 
                            ? <IconCategoryActive /> 
                            : <IconCategory />
                        }
                    </IconButton>
                        
                    </div>
                    <div className={classes.textName}>
                        Danh mục
                    </div>
                </div>
                <div className={classes.groupIcon} onClick={() => history.push(`/cart`)} >
                    <div className={classes.icon}>
                        <Badge
                            color="secondary"
                            badgeContent={carts.reduce((a, v) => a = a + v.quantity, 0)}
                            className={classes.cartIcon} overlap="rectangular"
                        >
                            <IconButton 
                                size='small' 
                                color="primary"
                                
                            >
                                {location.pathname === '/cart' 
                                    ? <IconCartActive /> 
                                    : <IconCart />
                                }
                            </IconButton>
                            
                        </Badge>

                    </div>
                    <div className={classes.textName}>
                        Giỏ hàng
                    </div>
                </div>
                <div className={classes.groupIcon} onClick={() => {
                    setOpen(true);
                    setDrawer('search');
                    setTitle('Tìm kiếm');
                }}>
                    <div className={classes.icon}>
                        <IconButton 
                            size='small' 
                            color="primary"
                        >
                            {drawer === 'search' 
                                ? <IconSearchActive /> 
                                : <IconSearch />
                            }
                        </IconButton>
                        
                    </div>
                    <div className={classes.textName}>
                        Tìm kiếm
                    </div>
                </div>
                <div className={classes.groupIcon} onClick={() => {
                        setOpen(true);
                        setDrawer('account');
                        setTitle('Tài khoản');
                    }}>
                    <div className={classes.icon}>
                        <IconButton 
                            size='small' 
                            color="primary"
                            
                        >
                            {drawer === 'account' || location.pathname === '/user/account'
                                ? <IconAccountActive /> 
                                : <IconAccount />
                            }
                        </IconButton>
                        
                    </div>
                    <div className={classes.textName}>
                        Tài khoản
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BottomMobieNav;
