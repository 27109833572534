import * as constants from './constants';
import * as services from './service';
import getStore from '../../configureStore';
import Errors from "../../shared/error/errors";
import p from './components/VocherCode/exaccum';

const actions = {
  doGetPromotion: (data) => async (dispatch) => {
    try {
      dispatch({ type: constants.GET_PROMOTION_START});
      const response = await services.getPromotion(data);

     let { promotions,  accumulations, liabilities} = response.data.data;
     //let { promotions,  accumulations, liabilities} = p.data;
      promotions = promotions ? _.orderBy(promotions.map((item) => {
        return {
          ...item,
          id_promo_exclude: !_.isEmpty(item.id_promo_exclude)
            ? item.id_promo_exclude.split(',').map(
              it => parseInt(it)
            )
            : [],
          childrens: item.childrens.map((e) => {
            return {
              ...e,
              id_promo_exclude: !_.isEmpty(e.id_promo_exclude)
                ? e.id_promo_exclude.split(',').map(
                  i => parseInt(i)
                )
                : [],
            }
          })
        }
      }), ['promo_type'], ['desc']) : [];

      dispatch({ 
        type: constants.GET_PROMOTION_SUCCESS,
        payload: {promotions, accumulations, liabilities}
      });
    } catch (error) {
      dispatch({ type: constants.GET_PROMOTION_ERROR});
      Errors.handle(error);
    }
  },
  doSaveOrder: (data) => async (dispatch) => {
    try {
      dispatch({ type: constants.CART_ORDER_SAVE_START});
      const response = await services.createOrder(data);
      dispatch({ 
        type: constants.CART_ORDER_SAVE_SUCCESS,
        payload: response.data.data.code_id
      });
      
      window.scrollTo({ top: 0, behavior: 'smooth' });

      dispatch({ type: 'SUBMIT_CART'});

    } catch (error) {
      dispatch({ type: constants.CART_ORDER_SAVE_ERROR});
      Errors.handle(error);
    }
  },
  /**
   * Xóa 1 dòng gỉ hàng
   * @param {*} id  id sản phẩm
   * @returns 
   */
  clearOneCart: (id) => async (dispatch) => {
    services.clearCart({id_product: id});
    dispatch({ 
      type: constants.CLEAR_ONE_CART,
      payload: {
        id 
      }
    });
  },

  onCheckCart: (id) => async (dispatch) => {
    dispatch({ 
      type: constants.ON_CHECK_CART,
      payload: {id}
    });
  },

  onCheckAllCart: (checked) => async (dispatch) => {
    try {
      services.checkedCart({checked});
      dispatch({ 
        type: constants.ON_CHECKALL_CART,
        payload: checked
      });
    } catch (error) {
      Errors.handle(error);
    }
    
  },

  doUpdateCart: (data) => async (dispatch) => {
  // lưu giỏ hàng
    try {
      dispatch({type: constants.UPDATE_CART_START});

      await services.updateCartOrder(data);

      dispatch({type: constants.UPDATE_CART_SUCCESS});
    } catch (error) {
      dispatch({type: constants.UPDATE_CART_ERROR});
    }
  },
  changeQuantityCart: (id, quantity) => async (dispatch) => {
    
    dispatch({ 
      type: constants.CHANGE_QUANTITY_CART,
      payload: {id,quantity}
    });
  },
  clearAllCart: () => async (dispatch) => {
    await services.clearCartOrder();
    dispatch({type: constants.CLEAR_CART});
  },


  /**
   * Thêm sản phẩm vào giỏ hàng
   * @param {*} item 
   * @param {*} quantity 
   * @returns 
   */
  addToCart: (item, quantity) => async (dispatch) => {
    let carts = getStore().getState().checkOut.carts.find(
      it => it.id_product === item.id_product
    );
    
    let checked = 1;
    let totalQuantity = quantity;
    if (carts !== undefined) { // tồn tại
      totalQuantity = (carts.quantity + quantity);
      checked = carts.checked === true ? 1 : 0;
    }

    services.updateCartOrder({
      id_product: item.id_product,
      quantity: totalQuantity,
      checked: checked
    });

    dispatch({ 
      type: constants.PLUS_CART,
      payload: {
        item,
        quantity
      }
    });
  },
  
  /**
   * Lấy giỏ hàng từ server
   * @returns 
   */
  addGetCart: () => async (dispatch) => {
    try {
      const response = await services.getCartOrder();
      dispatch({
        type: constants.GET_CART_SUCCESS,
        payload: response.data.data.carts
      });
    } catch (error) {
    }
  },

}

export default actions;