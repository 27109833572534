import React, { useState, useEffect } from 'react';
import { makeStyles, InputBase, Paper } from '@material-ui/core';
import SearchIcon from '../Icons/SearchIcon';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

const useStyles = makeStyles((theme) => ({
    search: {
        display: 'flex',
        height: '48px',
        width: '100%',
        background: '#F6F9FA',
        borderRadius: '40px',
        alignItems: 'center',
        "& ::placeholder": {
            fontWeight: 700,
            color: '#040404',
            fontSize: 18
        }
    },
    inputSearch: {
        height: '48px',
        flex: 1,
        paddingLeft: 10,
        paddingRight: 10
    },
    iconSearch: {
        marginTop: '5px',
        cursor: 'pointer',
        marginRight: 3,
    },
}))




let typingTimer = null;

function delay(callback, ms) {
  window.clearTimeout(typingTimer);
  typingTimer = setTimeout(function () {
    callback();
  }, ms);
}

const Inputsearchheader = (props) => {
    const classes = useStyles();
    const [txtInput, setTxtInput] = useState('');
    const [txtInputParse, setTxtInputParse] = useState([]);
    const history = useHistory();
    const location = useLocation();


    const search = queryString.parse(location.search);

    useEffect(() => {
        if (search.keyword === undefined || search.keyword === '') {
            setTxtInput('');
        } else {
            setTxtInput(search.keyword);
            setTxtInputParse(search.keyword.split(' '));
        }
    }, [search.keyword]);


    const handleChangeInput = e => {
        setTxtInput(e.target.value);
        setTxtInputParse(e.target.value.split(' ').filter((a) => a));

        
    }


    const  handleKeyDown = () => {
        delay(() => {
            if (props.hanhleSearch) {
                props.hanhleSearch(txtInputParse.join(' '))
            }
        }, 700);
    }

    return (
        <Paper className={classes.search} elevation={0}>
            <InputBase
                
                placeholder="Tìm Kiếm"
                className={classes.inputSearch}
          
                onChange={handleChangeInput}
                onClick={() => {
                    if (!_.isEmpty(txtInput) && props.setOpenSearch) {
                        props.hanhleSearch(txtInputParse.join(' '))
                    }
                }}
                onBlur={() => {
                    if (props.setOpenSearch) {
                        setTimeout(() => {
                            props.setOpenSearch(false);
                        }, 220)
                    }
                }}
                value={txtInput}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        if (props.setOpenSearch) {
                            props.setOpenSearch(false);
                        }
                        if (props.setOpen) {
                            props.setOpen();
                        }
                        history.push(`/search?keyword=${txtInputParse.join('+')}`);
                    }else {
                        if (props.hanhleSearch) {
                            handleKeyDown();
                        }
                    }
                }}
            />
            <div
                className={classes.iconSearch}
                onClick={() => {
                    history.push(`/search?keyword=${txtInputParse.join('+')}`);
                    if (props.setOpen) {
                        props.setOpen();
                    }

                    if (props.setOpenSearch) {
                        props.setOpenSearch(false);
                    }
                }}
            >
                <SearchIcon />
            </div>
        </Paper>
    );
};



export default Inputsearchheader;
