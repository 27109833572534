import * as constants from './constants';
import * as services from './service';
import getStore from '../../configureStore';
import Errors from "../../shared/error/errors";
const actions = {
  doHome: () => async (dispatch) => {
    try {
      dispatch({type: constants.GET_HOME_START});
      const resp = await services.getListProducts({order_by: 'DESC'});
      dispatch({
        type: constants.GET_HOME_SUCCESS,
        payload: {
          productsBestSeller: resp.data.data.product_lot.data,
          productsNew:  resp.data.data.product_new.data,
          productsRecentlyBought: resp.data.data.product_recent_purchase.data,
          productViews: resp.data.data.product_view.data
        }
      });

    } catch (error) {
      dispatch({type: constants.GET_HOME_ERROR});
      Errors.handle(error);
    }
  },

  // get banner
  doBanner: () => async (dispatch) => {
    try {
      dispatch({type: constants.GET_HOME_BANNER_START});
      var resBanner = await services.getBanner();
      dispatch({
        type: constants.GET_HOME_BANNER_SUCCESS,
        payload: resBanner.data.data
      });
    } catch (error) {
      dispatch({type: constants.GET_HOME_BANNER_ERROR});
      Errors.handle(error);
    }
  },

  doGetPost: () => async (dispatch) => {
    try {
      dispatch({type: constants.GET_HOME_POST_START});

      const resNew = await services.getNews();

      dispatch({
        type: constants.GET_HOME_POST_SUCCESS,
        payload: resNew.data.data
      })
      
    } catch (error) {
      dispatch({type: constants.GET_HOME_POST_ERROR});
      Errors.handle(error);
    }
  },

  doCategory: () => async (dispatch) => {
    try {
      const response = await services.getCategorys();
      dispatch({
        type: constants.GET_CATEGORY_SUCCESS,
        payload: response.data.data
      });
    } catch (error) {
      Errors.handle(error);
    }
  },
};

export default actions;

