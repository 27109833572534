import React from "react";
//import { isAuthenticated } from './permissionCusChecker';
import { Route } from "react-router-dom";
import Content from '../../Layout/AuthLayout/Content';

function AuthCustomerRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        <Content>
          <Component {...props} />
        </Content>
      }
    />
  )
}

export default AuthCustomerRoute;