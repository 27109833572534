import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import home from './public/HomePage/reducer';
import modal from '../components/ModalDialog/reducer';
import auth from './AuthCustomerPage/reducer';
import checkOut from './public/CartPage/reducer';
import modalConfirm from './../components/ModalConfirmOrder/reducer';
import order from './public/users/Purchase/reducer';
import search from './public/ProductFilter/reducer';
import pDetail from './public/ProductDetail/reducer';
import news from './public/News/reducer';
import bannerPromo from './public/Promotions/reducer';
import accum from './public/users/Accumulations/reducer';

const rootReducer = (history) => combineReducers({ // gộp nhiều reducer thành 1
  router: connectRouter(history),
  modal,
  home,
  auth,
  checkOut,
  modalConfirm,
  order,
  search,
  pDetail,
  news,
  bannerPromo,
  accum
});




export default rootReducer;