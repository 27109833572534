import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { primaryButtonColor } from '../../../Theme/master';
const useStyles = makeStyles((theme) => ({
    addCartButton: {
        border: '1px solid #00CFD0',
        boxSizing: 'border- box',
        borderRadius: '40px',
        color: primaryButtonColor,
        fontSize: '14px',

        '&:hover': {
            borderRadius: '40px',
            color: '#FFFFFF',
            background: primaryButtonColor,
        },

    },
    lg: {
        width: '227px',
        height:'40px'
    },
    sm: {
        width: '175px',
    },
    xs: {
        width: '160px',
    },
    xl:{
        width: '439px',
    },
   l:{
        width: '327px',
    }
}))

const Addcartbutton = (props) => {
    
    const classes = useStyles();
    const { color, size, className, ...rest } = props;

    const addCartButton = classNames({
        [classes.addCartButton]: true,
        [classes[size]]: size,
        [className]: className,
    });

    return (
        <Button
            id="demo-positioned-button"
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            className={addCartButton}
        >
            {props.name ? props.name :'THÊM VÀO GIỎ'}
        </Button>
    );
};


Addcartbutton.propTypes = {
    size: PropTypes.oneOf(['sm', 'md', 'lg','xs','xl','l']),
    className: PropTypes.string,
};


export default Addcartbutton;
