
const authCustomerRoutes = [
  {
    path: '/login',
    name: 'Đăng nhập',
    exact: true,
    component: import('./AuthCustomerPage/Login')
  },
  {
    path: '/reset-password',
    name: 'Gửi yêu cầu đăng nhập',
    exact: true,
    component: import('./AuthCustomerPage/ResetPassword')
  },
  {
    path: '/register',
    name: 'Đăng ký',
    exact: true,
    component: import('./AuthCustomerPage/Register')
  },
  {
    path: '/zalo',
    name: 'Đăng nhập key zalo',
    exact: true,
    component: import('./AuthCustomerPage/Zalo')
  },
  {
    path: '/locked',
    name: 'Khóa hệ thống',
    exact: true,
    component: import('./public/LockedPage')
  },
];

const homeRoutes = [
  
  {
    path: '/cart',
    name: 'Giỏ hàng',
    exact: true,
    component: import('./public/CartPage')
  },
  {
    path: '/lien-he',
    name: 'Liên hệ',
    exact: true,
    component: import('./public/Contact')
  },
];


const publicRoutes = [
  {
    path: '/',
    name: 'Trang Chủ',
    exact: true,
    component: import('./public/HomePage')
  },
  {
    path: '/search',
    name: 'Nhóm tìm kiếm',
    exact: true,
    component: import('./public/ProductFilter')
  },
  {
    path: '/product/:slug',
    name: 'Chi tiết sản phẩm',
    exact: true,
    component: import('./public/ProductDetail')
  },
  {
    path: '/news',
    name: 'Chi tiết bài viết',
    exact: true,
    component: import('./public/News')
  },
  {
    path: '/news/:slug',
    name: 'Chi tiết bài viết',
    exact: true,
    component: import('./public/NewDetail')
  },
  {
    path: '/khuyen-mai/:slug',
    name: 'Khuyến mãi chi tiết',
    exact: true,
    component: import('./public/Promotions')
  },
  {
    path: '/khuyen-mai',
    name: 'Khuyến mãi',
    exact: true,
    component: import('./public/Promotions/PromotionContent')
  },
  {
    path: '/ho-tro/:slug',
    name: 'Hỗ trợ',
    exact: true,
    component: import('./public/Support')
  },
];


const userRoutes = [
  {
    path: '/user/purchase',
    name: 'Đơn hàng',
    exact: true,
    component: import('./public/users/Purchase')
  },
  {
    path: '/user/account',
    name: 'Thông tin khách hàng',
    exact: true,
    component: import('./public/users/Account')
  },
  {
    path: '/user/purchase/:id/view',
    name: 'Đơn hàng chi tiết',
    exact: true,
    component: import('./public/users/PurchaseDetail')
  },
  {
    path: '/user/accumulations',
    name: 'Chương trình tích lũy',
    exact: true,
    component: import('./public/users/Accumulations')
  },
  {
    path: '/user/dashboard',
    name: 'Bảng điều khiển người dùng',
    exact: true,
    component: import('./public/users/Dashboard')
  },
  {
    path: '/user/total-sales/:slug',
    name: 'Tổng doanh sô',
    exact: true,
    component: import('./public/users/TotalSales')
  },
];




const allRoutes = {
  homeRoutes,
  authCustomerRoutes,
  userRoutes,
  publicRoutes
};
export default allRoutes