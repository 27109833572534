import produce, {setAutoFreeze} from 'immer';
import * as constants from './constants';
setAutoFreeze(false);

const initialState = {
  open: false,
  maxWidth: 'md',
  title: '',
  component: "",

}

const modalReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case constants.OPEN_MODAL:
        draft.open = true;
        draft.title = payload.title;
        if (payload.maxWidth !== undefined) {
          draft.maxWidth = payload.maxWidth;
        }
        
        draft.component = payload.component;
      break;

      case constants.CLOSE_MODAL:
        draft.open = false;
        draft.component = "";
        draft.title = "";
      break;

      default:
      break;
    }
  });

export default modalReducer;