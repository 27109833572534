import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';


const NotifiCart = () => {
  const history = useHistory();
  const matches = useMediaQuery('(max-width:500px)');
  const { openPushCart } = useSelector(state => state.checkOut);
  const dispatch = useDispatch();

  return (
    <div>
      <Snackbar
        style={{top: 50}}
        autoHideDuration={500}
        anchorOrigin={{ 
          vertical: 'top', 
          horizontal: 'right' 
        }}
        open={openPushCart}
        onClose={() => dispatch({type: 'CLOSE_NOTIFI_CART'})}
      >
        <Alert severity="info" >
        {!matches && <AlertTitle><b>Thông báo</b></AlertTitle>}
          <div>Thêm sản phẩm thành công vào giỏ hàng!</div>
          <br />
          {!matches && (
            <Button 
              style={{textTransform: 'none'}}
              fullWidth 
              size="small" 
              color="primary"
              variant="contained"
              onClick={() => history.push('/cart')}
          >
            Xem giỏ hàng
          </Button>
          )}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default NotifiCart;