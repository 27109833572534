import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import Copyright from '../../Core/Footer/CopyRight';
import QRCODE from '../../../assets/qrCode.png';
import ZALO from '../../../assets/zalo.png';
import YOUTUBE from '../../../assets/youtube.png';
import FACEBOOK from '../../../assets/facebook.png';
import { container } from '../../../Theme/master'
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import {
    getBanner
} from '../../../pages/public/HomePage/service';

import logoSaleNoti from '../../../assets/logoSaleNoti.png';

const useStyles = makeStyles((theme) => ({
    backGroundFooter: {
        paddingTop: '50px',
        background: '#FFFFFF',
        [theme.breakpoints.down(821)]: {
            paddingTop: 10
        }

    },
    gridContainerFooter: {
        marginLeft: '60px'
    },
    listMenuFooter: {
        listStyleType: 'none',
        columns: '2',
        marginTop: '10px',
        [theme.breakpoints.down(821)]: {
            columns: 1
        },
        marginLeft: '-40px'

    },
    listMenu: {
        listStyleType: 'none',
        marginLeft: '-40px'
    },
    paddingLi: {
        padding: '10px  30px  10px  0px',
        [theme.breakpoints.down(821)]: {
            padding: '10px  0px  10px  0px',
        }
    },
    textMenu: {
        color: '#102647',
    },
    textMenuHeader: {
        color: '#102647',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '25px',
    },
    container: {
        ...container
    },
    imgFooter: {
        padding: '5px 20px 10px 0 '
    },
    wrap: {
        display: 'flex',
        paddingTop: '20px'
    },
    wrapCategory: {
        display: 'flex',
        
    },
    connect: {
        paddingRight: '20px'
    },
    tilted: {
        fontWeight: '700',
        fontSize: '18px',
        paddingBottom: '10px'
    },
    copyright: {
        paddingTop: '30px'
    },
    qrCode: {
        marginTop: '15px'
    },
    paddingCategory: {
        marginBottom: '20px',
        paddingRight: '40px,'
    },
    bctTitle: {
        color: '#374957',
        fontSize: 18,
        fontWeight: 700,
        textTransform: 'uppercase',
        marginBottom: 20
    }

}));

export const ListConnect = () => {
    const classes = useStyles();
    return (
        <Grid item md={2} xs={6}>
            <Typography className={classes.tilted}>
                KẾT NỐI
            </Typography>
            <ul className={classes.listMenu}>
                <li className={classes.wrap}>
                    <img src={FACEBOOK} alt='facebook' className={classes.connect} />
                    <a className={classes.textMenuHeader} href="https://www.facebook.com/merap.congdongnhanvienyte" target="_blank" > <Typography > Facebook</Typography></a>
                </li>
                <li className={classes.wrap}>
                    <img src={ZALO} className={classes.connect} />
                    <a href="https://zalo.me/3891641387683803690" target="_blank"> <Typography>Zalo</Typography></a>
                </li>
                <li className={classes.wrap}>
                    <img src={YOUTUBE} className={classes.connect} />
                    <a href="https://www.youtube.com/@MERAP"  target="_blank" > <Typography>Youtube</Typography></a>
                </li>
            </ul>
        </Grid>
    )
}

const Footer3 = (props) => {
    
    const matches = useMediaQuery('(max-width:821px)');
    const classes = useStyles();
    const banner = useSelector(state => state.home.banner);
    const [banners, setBanners] = useState([]);

    useEffect(() => {
        setBanners(banner);
    }, [banner]);

    const  getBannerType = async () => {
        try {
            const res = await getBanner({type: 7});
            setBanners(res.data.data);
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        if (banner.length === 0) {
            getBannerType();
        }
    }, []);
    
    return (
        <div className={classes.backGroundFooter}>
            <div className={classes.container}>
                <Grid container spacing={4} >
                    <Grid item md={4} xs={6}>
                        <div className={classes.bctTitle}>
                            Công Ty Cổ Phần Dược Pha Nam
                        </div>
                        <p>Trụ sở: 436 Cao Thắng, P. 12, Q. 10, TP. Hồ Chí Minh.</p>
                        <p>Điện thoại: +84 888 333 489. Email: info@phanam.com.vn</p>
                        <p>GPDKKD: 0303441518</p>
                        <p>Do sở KH & ĐT TP.HCM cấp lần đầu ngày 05/05/2012 Đăng ký thay đổi lần thứ: 49, ngày 20/01/2018.</p>

                        <p style={{
                            marginTop: 30
                        }}>
                            <a href='http://online.gov.vn/Home/WebDetails/96180' target="_blank" >
                                <img 
                                    width={200} src={logoSaleNoti} 
                                />
                            </a>
                        </p>
                    </Grid>

                    <Grid item md={2} xs={6}>
                        <Typography className={classes.tilted}>
                            HỖ TRỢ
                        </Typography>

                        <ul className={classes.listMenu}>
                                <li className={classes.paddingLi}>
                                    <Link to={`/lien-he`}> 
                                        <Typography>Thông tin liên hệ</Typography>
                                    </Link>
                                </li>
                            {banners.filter(item => item.type === 7).map((bn, index) => {
                                return (
                                    <li className={classes.paddingLi} key={index}>
                                        <Link
                                            to={`/ho-tro/${bn.slug}`}
                                        > <Typography>{bn.banner_title}</Typography>
                                        </Link>
                                    </li>
                                )
                            })}
                        
                        </ul>
                    </Grid>
                    <Grid item md={2} xs={6}>
                        <Typography className={classes.tilted}>
                            DANH MỤC
                        </Typography>
                        <div className={classes.wrapCategory}>
                            <ul className={classes.listMenuFooter}>
                                {
                                    !_.isEmpty(props.category) ?
                                        props.category.filter((val) => val.menu_vertical === 1).map((items, i) => (
                                            <li className={classes.paddingCategory} key={i}>
                                                <Link to={`/search?id_category=${items.id_category}`}> <Typography>{items.category_name} </Typography> </Link>
                                            </li>
                                        ))
                                        : ''
                                }

                            </ul>

                        </div>
                    </Grid>
                    <ListConnect />
                    <Grid item md={2} xs={6}>
                        <Typography className={classes.tilted}>
                            ZALO OA
                        </Typography>
                        <img width={200} src={QRCODE} className={classes.qrCode} />
                    </Grid>


                </Grid>
                {/* <Divider /> */}
                <div className={classes.copyright}>
                    <Copyright />
                </div>
            </div>
        </div>
    );
};


export default Footer3;
