import React from 'react';
import { Modal } from 'antd';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';

export default class Message {

  static error(content) {
    Modal.error({
        title: content,
        icon: <CloseCircleOutlined />,
        //content: content,
        okText: 'Xác nhận',
        cancelText: ''
    });
  }

  static warn(content) {
    Modal.warn({
        title: content,
        icon: <ExclamationCircleOutlined />,
        okText: 'Xác nhận',
        cancelText: ''
    });
  }

  static success(content, arwFunc) {
    Modal.success({
        title: content,
        icon: <CheckCircleOutlined />,
        content: '',
        okText: 'Xác nhận',
        cancelText: '',
        onOk: () => {
            arwFunc();
        }
    });
  }

  static confirm(content, arwFunc) {
    Modal.confirm({
        title: content,
        icon: <ExclamationCircleOutlined />,
        okText: 'Xác nhận',
        cancelText: 'Hủy',
        onOk: () => {
            arwFunc();
        }
    });
  }
}