import React from 'react';
import {
    makeStyles,
    Grid
} from '@material-ui/core';
import { 
    container 
} from '../../../../Theme/master';

import SideBar, { ListMenuUser } from './SideBar';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(() => ({
    container: {
        ...container,
        paddingBottom: 20
    },
}));

const UserLayout = (props) => {
    const matches = useMediaQuery('(max-width:821px)');
    const classes = useStyles();
    return (
    <Grid className={classes.container}>
        <Grid container spacing={2}>
            {!matches && (
                <Grid item md={3} xs={12}>
                    <SideBar matches={matches} />
                </Grid>
            )}
            <Grid item md={9} xs={12}>
                <div>{props.children}</div>
            </Grid>
            {matches && (
                <Grid item xs={12}>
                    <ListMenuUser />
                </Grid>
            )}
            
            
        </Grid>
        
    </Grid> 
    )
}

export default UserLayout;