import React, { useEffect } from 'react';
import logo from '../../../assets/logo.png';
import {
    makeStyles,
} from '@material-ui/core';
import {
    socketDisconnect,
  } from '../../../sockets';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    logoMobie: {
        width: 170,
        cursor: 'pointer'
    },
    root: {
        height: 92,
        background: 'white',
        boxShadow: '0px 4px 4px rgba(227, 227, 227, 0.25)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    rootWp: {
        display: 'none',
        [theme.breakpoints.down(821)]: {
            display: 'block'
        }
    }
}))

const Header = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({type: 'RESET'});
        socketDisconnect();
    }, []);
    const classes = useStyles();
    const history = useHistory()
  return (
    <React.Fragment>
        <div className={classes.rootWp}>
            <div className={classes.root}>
            <img
                src={logo} 
                className={classes.logoMobie} 
                alt="logo phanam" 
                onClick={() => history.push('/')}
            />
        </div>
        </div>
    </React.Fragment>
  )
}

export default Header;
