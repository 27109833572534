import produce from 'immer';
import * as constants from './constants';

const initialState = {
    isLoadingProduct: false,
    isRedMoreLoading: false,
    banner: {},
    products: {
        data: [],
        total: 0
    }
}

const reducer = (state = initialState, { type, payload }) =>
    produce(state, draft => {

        switch (type) {

            case constants.GET_PROMOTIONS_PAGE_DETAIL_START:
                draft.isLoadingProduct = true;
            break;

            case constants.GET_PROMOTIONS_PAGE_DETAIL_SUCCESS:
                draft.isLoadingProduct = false;
                draft.banner = payload.banner;
                draft.products = payload.products;
            break;

            case constants.GET_PROMOTIONS_PAGE_DETAIL_ERROR:
                draft.isLoadingProduct = false;
                draft.products = {
                    data: [],
                    total: 0
                };
            break;


            case constants.GET_PROMOTIONS_PAGE_DETAIL_READMORE_START:
                draft.isRedMoreLoading = true;
            break;

            case constants.GET_PROMOTIONS_PAGE_DETAIL_READMORE_SUCCESS:
                draft.isRedMoreLoading = false;
                draft.products.total = payload.total;
                draft.products.data = draft.products.data.concat(
                    payload.data
                );
            break;

            case constants.GET_PROMOTIONS_PAGE_DETAIL_READMORE_ERROR:
                draft.isRedMoreLoading = false;
            break;

            case `RESET_STATE`:
                draft.isLoadingProduct = false;
                draft.isRedMoreLoading = false;
                draft.products = {
                    data: [],
                    total: 0
                };
                break;

            default:
                break;
        }
    });

export default reducer;