import React, { Fragment } from "react";
import { isAuthenticated } from './permissionCusChecker';
import { Redirect, Route } from "react-router-dom";
import Header from "../../Layout/HomeLayout/Header";
import Footer from "../../Layout/HomeLayout/Footer";
import UserLayout from '../../public/users/components/Layout';

function UserRoute({ component: Component, ...rest }) {

  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated() ? (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        ) : (
          <Fragment>
              <Header/>
              <UserLayout>
                <Component {...props}  />
              </UserLayout>
              
            <Footer />
          </Fragment>

        )
      }
    />
  )
}

export default UserRoute;