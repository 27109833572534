import produce from 'immer';
import * as constants from './constants';
//import _ from 'lodash';

const initialState = {
  isLoadingOrder: false,
  listOrder: {
    data: [],
    total: 0
  },
  orderDetail: {},
  isLoadingOrderDetail: false,
  isLoadingCancelOrder: false,
  isLoadingRepurchaseOrder: false,
  idOrder: 0
}

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      
      case constants.GET_LIST_ORDER_START:
        draft.isLoadingOrder = true;

      break;

      case constants.GET_LIST_ORDER_SUCCESS:
        draft.isLoadingOrder = false;
        draft.listOrder = payload.listOrder;
      break;

      case constants.GET_LIST_ORDER_ERROR:
        draft.isLoadingOrder = false;
        draft.listOrder = {
          data: [],
          total: 0
        };
      break;


      case constants.GET_DETAIL_ORDER_START:
        draft.isLoadingOrderDetail = true;
        draft.orderDetail = {};
      break;

      case constants.GET_DETAIL_ORDER_SUCCESS:
        draft.isLoadingOrderDetail = false;
        draft.orderDetail = payload.orderDetail;
      break;

      case constants.GET_DETAIL_ORDER_ERROR:
        draft.isLoadingOrderDetail = false;
        draft.orderDetail = {};
      break;


      case constants.CANCEL_ORDER_START:
        draft.isLoadingCancelOrder = true;
        draft.idOrder = payload.idOrder;
      break;

      case constants.CANCEL_ORDER_SUCCESS:
        draft.isLoadingCancelOrder = false;
        let index = draft.listOrder.data.findIndex(
          item => item.id_order === payload.idOrder
        );
        draft.listOrder.data[index].process_name = 'Đơn hàng hủy';
        draft.listOrder.data[index].process_color = '#FF3C31';
        draft.listOrder.data[index].id_group = 7;
        draft.listOrder.data[index].id_process = 13;
        draft.listOrder.data[index].status_cancel = 0;
        draft.idOrder = 0;
      break;

      case constants.CANCEL_ORDER_ERROR:
        draft.isLoadingCancelOrder = false;
        draft.idOrder = 0;
      break;



      case constants.REPURCHASE_ORDER_START:
        draft.isLoadingRepurchaseOrder = true;
        draft.idOrder = payload.idOrder;
      break;

      case constants.REPURCHASE_ORDER_SUCCESS:
        draft.isLoadingRepurchaseOrder = false;
        draft.idOrder = 0;
      break;


      case constants.REPURCHASE_ORDER_ERROR:
        draft.isLoadingRepurchaseOrder = false;
        draft.idOrder = 0;
      break;

      case `RESET_STATE`:
        return initialState;

      default:
      break;
    }
  });

export default reducer;