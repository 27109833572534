import produce from 'immer';
import * as constants from './constants';
//import _ from 'lodash';

const initialState = {
  isCatLoading: false,
  isLoadingProduct: false,
  isLoadingBanner: false,
  isLoadingPost: false,
  categorys: [],
  products: {
    data: [],
    total: 0
  },
  productsBestSeller: [],
  productsNew: [],
  productsRecentlyBought: [],
  productViews: [], // danh sách sản phẩm đã xem
  banner: [],
  news: []
}

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {

    switch (type) {
      case constants.GET_HOME_START:
        draft.isLoadingProduct = true;
        break;

      case constants.GET_HOME_SUCCESS:
        draft.isLoadingProduct = false;
        draft.productsBestSeller = payload.productsBestSeller;
        draft.productsNew = payload.productsNew;
        draft.productsRecentlyBought = payload.productsRecentlyBought;
        draft.productViews = payload.productViews;
        break;

      case constants.GET_HOME_ERROR:
        draft.isLoadingProduct = false;
        draft.productsBestSeller = [];
        draft.productsNew = [];
        draft.productsRecentlyBought = [];
        draft.productViews = [];
        break;

      case constants.GET_CATEGORY_SUCCESS:
        draft.isCatLoading = false;
        draft.categorys = payload;
        break;


      case constants.GET_HOME_BANNER_START:
        draft.isLoadingBanner = true;
        draft.banner = [];
      break;

      case constants.GET_HOME_BANNER_SUCCESS:
        draft.isLoadingBanner = false;
        draft.banner = payload;
      break;

      case constants.GET_HOME_BANNER_ERROR:
        draft.isLoadingBanner = false;
        draft.banner = [];
      break;


      case constants.GET_HOME_POST_START:
        draft.isLoadingPost = true;
        draft.news = [];
      break;

      case constants.GET_HOME_POST_SUCCESS:
        draft.isLoadingPost = false;
        draft.news = payload;
      break;

      case constants.GET_HOME_POST_ERROR:
        draft.isLoadingPost = false;
        draft.news = [];
      break;

      case `RESET_STATE`:
        draft.productsBestSeller = [];
        draft.productsNew = [];
        draft.productsRecentlyBought = [];
        draft.productViews = [];
        break;

      default:
        break;
    }
  });

export default reducer;