import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Category from './components/Category';
import Account from './components/Account';
import InputSearchHeader from '../../../components/Core/CustomInput/InputSearchHeader';


const useStyles = makeStyles({
  root: {
    '&>.MuiDrawer-paper': {
      width: '100%',
    }
  },
  list: {
    height: 'calc(100% - 54px)',
    padding: '40px 20px'
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    fontSize: 24,
    color: '#52627C'
  },
  iconClose: {
    color: '#52627C',
  },
  content: {
    paddingTop: 20,
    paddingBottom: 20
  }

});

const DrawerMobie = ({
  drawer,
  setDrawer,
  open,
  setOpen,
  title
}) => {
  const classes = useStyles();
  const handleOpen = () => {
    setOpen(false);
    setDrawer('');
  }
  return (
    <div>
        <React.Fragment>
          <Drawer  
            className={classes.root}
            open={open} onClose={() => setOpen(false)}
          >
            <div className={classes.list}>
              <div className={classes.header}>
                <div className={classes.title}>{title}</div>
                <IconButton
                  size='small'
                  onClick={() => {
                    setOpen(false);
                    setDrawer('');
                  }}
                >
                  <CloseIcon className={classes.iconClose} />
                </IconButton>
                
              </div>

              <div className={classes.content}>
                {drawer === 'category' && <Category setOpen={setOpen} />}
                {drawer === 'account' && <Account setOpen={setOpen} />}
                {drawer === 'search' && <div style={{marginTop: 20}}><InputSearchHeader setOpen={handleOpen} /></div>}
              </div>
            </div>
          </Drawer>
        </React.Fragment>
    </div>
  );
}

export default DrawerMobie;