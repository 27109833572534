import React from 'react';
import PropTypes from 'prop-types';


const AllProductIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.32533 17.1221C8.89671 17.0512 8.46301 17.0025 8.04031 16.9058C6.57335 16.5699 5.30652 15.8596 4.22714 14.8109C4.20768 14.7918 4.19287 14.7681 4.18652 14.7596C4.6088 14.3364 5.0243 13.9195 5.43981 13.5031C8.02 16.1658 12.3655 15.7799 14.6021 13.1965C16.7613 10.7021 16.7024 7.04791 14.4811 4.67101C12.1484 2.17495 8.01069 1.9362 5.4525 4.49417C5.03615 4.07859 4.61937 3.663 4.16113 3.20628C4.57114 2.88611 4.9786 2.51717 5.43346 2.22117C9.55931 -0.465721 14.9782 1.02148 17.1704 5.43094C19.4675 10.052 16.7727 15.7608 11.7481 16.9054C11.3254 17.0016 10.8926 17.0512 10.4644 17.1229C10.0853 17.1221 9.70529 17.1221 9.32533 17.1221Z" fill="#3C5264" />
            <path d="M8.08966 12.6077C8.08966 11.6798 8.08966 10.8037 8.08966 9.90596C5.38168 9.90596 2.69909 9.90596 0 9.90596C0 9.30209 0 8.72112 0 8.10961C2.68217 8.10961 5.37025 8.10961 8.08204 8.10961C8.08204 7.20338 8.08204 6.32598 8.08204 5.38879C9.60485 6.60926 11.0837 7.7941 12.5891 9.00058C11.0951 10.1986 9.61289 11.3868 8.08966 12.6077Z" fill="#3C5264" />
        </svg>
    );
};


AllProductIcon.propTypes = {

};


export default AllProductIcon;
