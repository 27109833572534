import produce from 'immer';
import * as constants from './constants';
//import _ from 'lodash';

const initialState = {
  open: false,
  data: {
      title: 'Huỷ Đơn Hàng',
      text: 'Đơn hàng đang được giao. Quý khách hàng không thể huy đơn hàng này.',
      close: true,
      txtCancel: 'Bỏ qua',
      txtConfirm: 'Hủy đơn hàng',
      type: 'confirm',
      onConfirm: () => {

      }
  }
}

const reducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {

    switch (type) {

      case constants.OPEN_MODAL_CONFIRM:
        draft.open = true;
        draft.data = payload;
      break;

      case constants.CLOSE_MODAL_CONFIRM:
        draft.open = false;
      break;


      case `RESET_STATE`:

        draft.open = false;
        draft.data = {};
      break;

      default:
        break;
    }
  });

export default reducer;