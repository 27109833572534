
import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { message } from 'antd';
import {
    deleteAvatar
} from '../Account/service';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
    button: {
        minWidth: 35,
        height: 35,
        borderRadius: 30,
        position: 'absolute',
        bottom: 45,
        right: 25,
        padding: 0
    }
}));

const ButtonDelete = ({ setLoading }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleDelete = async () => {
        try {
            setLoading(true);
            await deleteAvatar();
            dispatch({type: `USER_UPDATE_AVATAR`, payload: {
                avatar_thumb: null,
                avatar: null,
                avatar_name: null,
            }});
            setLoading(false);
            message.success(`Xóa ảnh đại diện thành công`);

        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    return (
        <Button
            title='Xóa ảnh'
            className={classes.button}
            variant='contained'
            color='secondary'
            onClick={handleDelete}
        >
            <DeleteIcon fontSize='small' />

        </Button>
    )
}

export default ButtonDelete;
