export const GET_LIST_ORDER_START = `GET_LIST_ORDER_START`;
export const GET_LIST_ORDER_SUCCESS = `GET_LIST_ORDER_SUCCESS`;
export const GET_LIST_ORDER_ERROR = `GET_LIST_ORDER_ERROR`;


export const GET_DETAIL_ORDER_START = `GET_DETAIL_ORDER_START`;
export const GET_DETAIL_ORDER_SUCCESS = `GET_DETAIL_ORDER_SUCCESS`;
export const GET_DETAIL_ORDER_ERROR = `GET_DETAIL_ORDER_ERROR`;

export const CANCEL_ORDER_START = `CANCEL_ORDER_START`;
export const CANCEL_ORDER_SUCCESS = `CANCEL_ORDER_SUCCESS`;
export const CANCEL_ORDER_ERROR = `CANCEL_ORDER_ERROR`;



export const REPURCHASE_ORDER_START = `REPURCHASE_ORDER_START`;
export const REPURCHASE_ORDER_SUCCESS = `REPURCHASE_ORDER_SUCCESS`;
export const REPURCHASE_ORDER_ERROR = `REPURCHASE_ORDER_ERROR`;