import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import parse from 'html-react-parser';
import { MenuItem, Grid, Typography, Menu } from '@material-ui/core';
//
import Cart from '../../../components/Modules/Header/Cart';
import Category from '../../../components/Modules/Header/Category';
import InputSearchHeader from '../../../components/Core/CustomInput/InputSearchHeader';
import HeadphoneIcon from '../../../components/Core/Icons/HeadPhoneIcon';
import MarkerIcon from '../../../components/Core/Icons/MarkerIcon';
import UserIcon from '../../../components/Core/Icons/UserIcon';
import logo from '../../../assets/logo.svg';
import logo_white from '../../../assets/logo_white.svg';
//
import { makeStyles } from '@material-ui/core/styles';
import { container } from '../../../Theme/master'
import actions from "../../AuthCustomerPage/actions";
import actionsHome from '../../public/HomePage/action';
import actionCart from '../../public/CartPage/actions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ModalConfirmOrder from '../../../components/ModalConfirmOrder';
import { Avatar } from 'antd';
import NotifiCart from '../../public/HomePage/components/NotifiCart';
import BottomMobieNav from './BottomMobieNav';
import ListProductSearch from './components/ListProductSearch';
import * as serviceSearch from '../../public/ProductFilter/service';
import {
  configSocket
} from '../../../sockets';
import { isAuthenticated } from '../../shared/routes/permissionCusChecker';

const useStyles = makeStyles((theme) => ({
  appBar: {
    padding: '30px 30px 0px 40px'
  },
  logo: {
    width: 210,
    cursor: 'pointer'
  },
  wrapDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  marginPadding: {
    display: 'flex',

  },
  groupIcon: {
    display: 'flex',
    marginLeft: '113px'
  },
  text: {
    fontWeight: 'bold',
    marginLeft: '16px',
    fontSize: '14px',
    color: 'white',
    '&>p': {
      color: 'white',
    },
    "&:hover": {
      color: 'white',
    },
    '&>svg': {
      color: 'white',
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  listMenu: {
    color: 'white',
    display: 'flex',
    listStyleType: 'none',
    padding: '20px 0px 0px 0px',
  },
  paddingLi: {
    paddingLeft: '40px',
  },
  textMenu: {
    color: 'white',
    fontSize: '14px',
    '&:hover': {
      color: '#12A79D',
    },
  },

  header: {
    background: '#009688',
    padding: '20px',

  },
  backgroundHeader: {
    color: 'white',
    paddingTop: 25,
    background: '#00A79D',
    overflow: 'hidden',
    height: 180,
    [theme.breakpoints.down(821)]: {
      height: 90,
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 4px 4px rgba(227, 227, 227, 0.25)'
    },
  },
  logoMobie: {
    width: 155,
    height: 40
  },
  container: {
    ...container,
  },
  container2: {
    ...container,
    alignItems: 'center',
    marginTop: 20
  },
  paddingHeader: {
    padding: ''
  },
  category: {
    paddingTop: '20px',
    paddingBottom: '0px'

  },
  categoryPadding: {
    padding: '0px',
  },
  fontWeight: {
    fontWeight: '600'
  },
  wrapIcon: {
    display: 'flex',
    padding: '10px',
    alignItems: 'center',
    gap: 20,
    justifyContent: 'end'
  },
  MuiGridItem: {
    padding: '0px!important',
    marginTop: '14px',
  },
  account: {
    marginTop: '65px',

    borderRadius: '10px',

  },
  paddingMenu: {
    padding: '10px',
  }
}));

const Header = (props) => {
  const matches = useMediaQuery('(max-width:821px)');
  const listCategory = useSelector(state => state.home.categorys);
  const { user } = useSelector(state => state.auth);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const open = Boolean(anchorEl);
  const location = useLocation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = async () => {
    dispatch(actions.doLogOut());
  }



  const [openSearch, setOpenSearch] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [products, setProducts] = useState({ data: [], total: 0 });

  useEffect(() => {
    if (user.customer_name === undefined) {
      dispatch(actionCart.addGetCart());
      dispatch(actions.doGetUserInfo());
    }else {
      configSocket(user);
    }
    if (_.isEmpty(listCategory)) {
      dispatch(actionsHome.doCategory())
    }
    return () => {
      setOpenSearch(false);
      setKeyword('');
      setProducts({ data: [], total: 0 });
    }

  }, []);



  const hanhleSearch = async (value) => {
    try {
      if (!_.isEmpty(value)) {
        const res = await serviceSearch.getProducts({
          keyword: value,
          limit: 5
        });

        if (res.data.total > 0) {
          setKeyword(value);
          setOpenSearch(true);
          setProducts(res.data);
        } else {
          setOpenSearch(false);
        }
      } else {
        setOpenSearch(false);
      }

    } catch (error) {
      setOpenSearch(false);
    }
  }


  return (

    <div className={classes.backgroundHeader}>
      <ModalConfirmOrder />
      <NotifiCart />
      {!matches ? (
        <React.Fragment>
          <Grid container spacing={1} className={classes.container}  >
            <Grid item sm={3}>
              <img
                className={classes.logo} alt="Dược Pha Nam" src={logo_white}
                onClick={() => history.push('/')}
              />
            </Grid>
            <Grid item sm={4} >
              <InputSearchHeader
                hanhleSearch={hanhleSearch}
                setOpenSearch={setOpenSearch}
              />

              {openSearch && (<ListProductSearch
                keyword={keyword}
                products={products}
                setOpenSearch={setOpenSearch}
              />)}
            </Grid>
            <Grid item sm={5} className={classes.wrapIcon}>
              <div className={classes.marginPadding}>
                <div className={classes.icon}>
                  <HeadphoneIcon />

                  <div className={classes.text} >
                    <Typography>Hotline</Typography>

                    <Typography className={classes.fontWeight}>
                      <a style={{ color: 'white' }} href='tel:+84888333489'>+84 888 333 489</a>
                    </Typography>
                  </div>
                </div>
              </div>
              {isAuthenticated() && <div className={classes.marginPadding}>
                <div className={classes.icon} onClick={() => history.push('/user/purchase')}>
                  <MarkerIcon />
                  <div className={classes.text}>
                    <Typography>Theo dõi</Typography>
                    <Typography className={classes.fontWeight}> Đơn Hàng </Typography>
                  </div>
                </div>
              </div>}
              {isAuthenticated() ? <div className={classes.marginPadding}>
                <div className={classes.icon}
                  id="account-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  {user.avatar ? <Avatar src={user.avatar_thumb} /> : <UserIcon />}

                  <div className={classes.text}>
                    <Typography>Tài Khoản</Typography>
                    <Typography className={classes.fontWeight}> {user ? user.customer_name : ''} </Typography>
                  </div>
                </div>
                <Menu
                  id="account-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'account-button',
                  }}
                  className={classes.account}
                >
                  <div className={classes.paddingMenu}>
                    <MenuItem onClick={() => history.push('/user/account')}>Thông tin tài khoản</MenuItem>

                    <MenuItem onClick={handleLogout}>Đăng xuất</MenuItem>
                  </div>

                </Menu>

              </div> : (
                <div className={classes.marginPadding}>
                  <div className={classes.text}>
                    <Typography>Tài Khoản</Typography>
                    <Typography style={{
                      cursor: 'pointer',
                      fontWeight: 600,
                      color: '#12A79D'
                    }} onClick={() => history.push('/login')} >Đăng nhập</Typography>
                  </div>
                </div>
              )}


            </Grid>

          </Grid>
          <Grid container className={classes.container2}  >
            <Grid item sm={3} className={classes.MuiGridItem} >
              <Category category={listCategory} />
            </Grid>
            <Grid item sm={6} >
              <ul className={classes.listMenu}>
                {!_.isEmpty(listCategory) ?
                  listCategory.filter((val) => val.menu_horizontal === 1).map((items, i) => (
                    <li className={classes.paddingLi} key={i}>
                      <div className={classes.wrapDiv}>
                        {
                          items.icon ? parse(items.icon) : ''
                        }
                        <Link className={classes.text}
                          to={items.path_route === null ? `/search?id_category=${items.id_category}` : items.path_route}
                        >{items.category_name}</Link>
                      </div>
                    </li>
                  )) : ''
                }

              </ul>
            </Grid>
              <Grid item sm={3} className={classes.wrapDiv} style={{ justifyContent: 'end' }}>
                {
                  location.pathname === '/cart' ? '' : <Cart />
                }
              </Grid>
          
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <img
            onClick={() => history.push('/')}
            src={logo_white}
            className={classes.logoMobie}
            alt="logo phanam"
          />
          <BottomMobieNav />
        </React.Fragment>

      )}

    </div>

  )
}

export default Header;
