export const GET_CATEGORY_POST_START = `GET_CATEGORY_POST_START`;
export const GET_CATEGORY_POST_SUCCESS = `GET_CATEGORY_POST_SUCCESS`;
export const GET_CATEGORY_POST_ERROR = `GET_CATEGORY_POST_ERROR`;

export const GET_TAG_POST_START = `GET_TAG_POST_START`;
export const GET_TAG_POST_SUCCESS = `GET_TAG_POST_SUCCESS`;
export const GET_TAG_POST_ERROR = `GET_TAG_POST_ERROR`;

export const GET_POP_POST_START = `GET_POP_POST_START`;
export const GET_POP_POST_SUCCESS = `GET_POP_POST_ERROR`;
export const GET_POP_POST_ERROR = `GET_POP_POST_ERROR`;

export const GET_POST_START = `GET_POST_START`;
export const GET_POST_SUCCESS = `GET_POST_ERROR`;
export const GET_POST_ERROR = `GET_POST_ERROR`;

export const GET_POST_DETAIL_START = `GET_POST_DETAIL_START`;
export const GET_POST_DETAIL_SUCCESS = `GET_POST_DETAIL_SUCCESS`;
export const GET_POST_DETAIL_ERROR = `GET_POST_DETAIL_ERROR`;