import ascusapi from '../../../../apis/ascusapi';

export const deleteFile = async (data) => { // xóa file cv user
    const respone = await ascusapi.post(`ecommerce/auth/delete-file`, data);
    return respone;
}

export const deleteAvatar = async () => { // xóa file cv user
    const respone = await ascusapi.post(`ecommerce/auth/delete-avatar`);
    return respone;
}