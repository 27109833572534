export const FIND_LIST_ACCUM_START = `FIND_LIST_ACCUM_START`;
export const FIND_LIST_ACCUM_SUCCESS = `FIND_LIST_ACCUM_SUCCESS`;
export const FIND_LIST_ACCUM_ERROR = `FIND_LIST_ACCUM_ERROR`;

export const FIND_STATISTICS_ACCUM_START = `FIND_STATISTICS_ACCUM_START`;
export const FIND_STATISTICS_ACCUM_SUCCESS = `FIND_STATISTICS_ACCUM_SUCCESS`;
export const FIND_STATISTICS_ACCUM_ERROR = `FIND_STATISTICS_ACCUM_ERROR`;

export const FIND_ACCUM_ORDER_HISTORY_START = `FIND_ACCUM_ORDER_HISTORY_START`;
export const FIND_ACCUM_ORDER_HISTORY_SUCCESS = `FIND_ACCUM_ORDER_HISTORY_SUCCESS`;
export const FIND_ACCUM_ORDER_HISTORY_ERROR = `FIND_ACCUM_ORDER_HISTORY_ERROR`;


export const FIND_ACCUM_JOIN_HISTORY_START = `FIND_ACCUM_JOIN_HISTORY_START`;
export const FIND_ACCUM_JOIN_HISTORY_SUCCESS = `FIND_ACCUM_JOIN_HISTORY_SUCCESS`;
export const FIND_ACCUM_JOIN_HISTORY_ERROR = `FIND_ACCUM_JOIN_HISTORY_ERROR`;