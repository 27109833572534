export const GET_TRADEMARKS_START = `GET_TRADEMARKS_START`;
export const GET_TRADEMARKS_SUCCESS = `GET_TRADEMARKS_SUCCESS`;
export const GET_TRADEMARKS_ERROR = `GET_TRADEMARKS_ERROR`;

export const GET_BANER_FILTER_START = `GET_BANER_FILTER_START`;
export const GET_BANER_FILTER_SUCCESS = `GET_BANER_FILTER_SUCCESS`;
export const GET_BANER_FILTER_ERROR = `GET_BANER_FILTER_ERROR`;

export const GET_PRODUCT_FILTER_START = `GET_PRODUCT_FILTER_START`;
export const GET_PRODUCT_FILTER_SUCCESS = `GET_PRODUCT_FILTER_SUCCESS`;
export const GET_PRODUCT_FILTER_ERROR = `GET_PRODUCT_FILTER_ERROR`;


export const GET_PRODUCT_READMORE_START = `GET_PRODUCT_READMORE_START`;
export const GET_PRODUCT_READMORE_SUCCESS = `GET_PRODUCT_READMORE_SUCCESS`;
export const GET_PRODUCT_READMORE_ERROR = `GET_PRODUCT_READMORE_ERROR`;