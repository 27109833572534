export const GET_HOME_START = `GET_HOME_START`;
export const GET_HOME_SUCCESS = `GET_HOME_SUCCESS`;
export const GET_HOME_ERROR = `GET_HOME_ERROR`;

export const GET_CATEGORY_REQUEST = `GET_CATEGORY_REQUEST`;
export const GET_CATEGORY_SUCCESS = `GET_CATEGORY_SUCCESS`;
export const GET_CATEGORY_FAILURE = `GET_CATEGORY_FAILURE`;


export const GET_HOME_BANNER_START = `GET_HOME_BANNER_START`;
export const GET_HOME_BANNER_SUCCESS = `GET_HOME_BANNER_SUCCESS`;
export const GET_HOME_BANNER_ERROR = `GET_HOME_BANNER_ERROR`;


export const GET_HOME_POST_START = `GET_HOME_POST_START`;
export const GET_HOME_POST_SUCCESS = `GET_HOME_POST_SUCCESS`;
export const GET_HOME_POST_ERROR = `GET_HOME_POST_ERROR`;
