import React from 'react'
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import actions from '../../../AuthCustomerPage/actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    item: {
        cursor: 'pointer',
        marginTop: 20
    }
}));

export default function Account(props) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch  = useDispatch();
    return (
        <div>
            <div 
                className={classes.item}
                onClick={() => {
                    history.push(`/user/account`);
                    props.setOpen(false);
                }}
            >
                Tài khoản
            </div>
            <div 
                className={classes.item}
                onClick={() => {
                    history.push(`/user/account?type=2`);
                    props.setOpen(false);
                }}
            >
                Đổi mật khẩu
            </div>
            <div 
                className={classes.item}
                onClick={() => {
                    history.push(`/user/purchase`);
                    props.setOpen(false);
                }}
            >
                Quản lý đơn hàng
            </div>
            <div 
                className={classes.item}
                onClick={() => {
                    history.push(`/user/accumulations`);
                    props.setOpen(false);
                }}
            >
                Chương trình tích lũy
            </div>
            <div 
                className={classes.item}
                onClick={() => {
                    dispatch(actions.doLogOut());
                    props.setOpen(false);
                }}
            >
                Đăng xuất
            </div>
        </div>
    )
}
