/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// ##############################
// // // Function that converts from hex color to rgb color
// // // Example: input = #9c27b0 => output = 156, 39, 176
// // // Example: input = 9c27b0 => output = 156, 39, 176
// // // Example: input = #999 => output = 153, 153, 153
// // // Example: input = 999 => output = 153, 153, 153
// #############################
import { createTheme } from '@material-ui/core/styles';

const customTheme = createTheme({
  breakpoints: {
    values: {
      mobile: 320,
      tablet: 650,
      desktop: 1024,
    },
  },
});


// ##############################
// // // Variables - Styles that are used on more than one component
// #############################



const container = {
  paddingRight: '15px',
  paddingLeft: '15px',
  marginRight: 'auto',
  marginLeft: 'auto',
  maxWidth: 1400,
  '@media (max-width: 768px)': {
    maxWidth: '100%',
  },
  '@media (min-width: 992px)': {
    maxWidth: '970px',
  },
  '@media (min-width: 1200px)': {
    maxWidth: '1400px',
  },
  '&:before,&:after': {
    display: 'table',
    content: '" "',
  },
  '&:after': {
    clear: 'both',
  },
};

const defaultFont = {
  fontFamily: '"Manrope",Roboto", "Helvetica", "Arial", "sans-serif"',
  fontSize: '1rem',
  fontWeight: '300',
  lineHeight: '1.5em',
};

const primaryColor = ['#00A79D'];
const warningColor = [
  '#F8A900',
  '#ffa726',
  '#fb8c00',
  '#ffa21a',
  '#f57c00',
  '#faf2cc',
  '#fcf8e3',
];
const dangerColor = [
  '#EC4847',
  '#ef5350',
  '#e53935',
  '#f55a4e',
  '#d32f2f',
  '#ebcccc',
  '#f2dede',
];
const successColor = [
  '#00B341',
  '#66bb6a',
  '#43a047',
  '#5cb860',
  '#388e3c',
  '#d0e9c6',
  '#dff0d8',
];
const infoColor = [
  '#47C8F5',
  '#26c6da',
  '#00acc1',
  '#00d3ee',
  '#0097a7',
  '#c4e3f3',
  '#d9edf7',
];
const roseColor = ['#e91e63', '#ec407a', '#d81b60', '#eb3573', '#c2185b'];
const grayColor = [
  '#999',
  '#777',
  '#434343',
  '#AAAAAA',
  '#D2D2D2',
  '#DDD',
  '#555555',
  '#333',
  '#eee',
  '#ccc',
  '#e4e4e4',
  '#E5E5E5',
  '#f9f9f9',
  '#f5f5f5',
  '#495057',
  '#e7e7e7',
  '#212121',
  '#c8c8c8',
  '#505050',
  '#868E92',
];
const primaryButtonColor = "#00A79D";
const primaryButtonHoverColor = "#47C8F5";
const primaryBackgroundColor = "#FAFAFA";
const primaryTextColor = "#2D292A";
const secondaryTextColor = "#3c4858"; //"#868E92";
const tertiaryTextColor = "#868E92"; // "##A5A5A5";
const blackColor = '#000';
const lightGrayColor = '#FAFAFA';
const whiteColor = '#FFF';
const redColor='#EB1C24';
const inputTextBackgroundColor = '#F0F0F0';
const blackColorTitle='#52627C';
const  line={
  border: 'none',
  borderTop: '1px dashed   #D2DCE1',
  color: '#fff',
  backgroundColor: '#fff',
  height: '1px',
  width: '100%',
}
const tilted={
  fontWeight: '600',
  fontSize: '24px',
  lineHeight: '33px',
  color: '#0E223E',
  marginTop:'2px'
}
const allButton= {
  position: 'absolute',
  left: '1200px',
  top: '30px'
}




export {
  customTheme,

  primaryButtonColor,
  primaryButtonHoverColor,
  primaryBackgroundColor,
  primaryTextColor,
  secondaryTextColor,
  tertiaryTextColor,
  inputTextBackgroundColor,
  line,
  tilted,
  blackColorTitle,
  //variables

  container,
  allButton,
  defaultFont,
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  lightGrayColor,
  blackColor,
  whiteColor,
  redColor,

};
