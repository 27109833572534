export default {
    "data": {
        "promotions": [
            {
                "id_promo_info": 49,
                "id_group": 3,
                "promo_code": "202301-DH-QD01-PMC-PCL-CTD-CK",
                "promo_code_dms": "CS2301M037",
                "promo_name": "Đơn hàng (Không bao gồm sản phẩm XOS) >= 1triệu ck 8%",
                "promo_invoice_name": "Chiết khấu đơn hàng theo chính sách bán hàng số",
                "description": "CSBH 2301 - Nhóm PMC,PCL,CTD - ĐH KS/CL >= 1triệu ck 8%",
                "contents": "<h1><strong>Ch&iacute;nh s&aacute;ch b&aacute;n h&agrave;ng năm&nbsp;2023</strong></h1>\n\n<p><strong>Ưu đ&atilde;i</strong></p>\n\n<ul>\n\t<li>Mua mỗi&nbsp;10 hộp&nbsp;sản phẩm Ebysta - Tặng 1 hộp&nbsp;Ebysta</li>\n\t<li>Mua mỗi&nbsp;10 hộp sản phẩm Benita - Tặng 1 hộp&nbsp;Benita</li>\n\t<li>Mua mỗi 10 hộp&nbsp;sản phẩm Medoral - Tặng 1 hộp&nbsp;Medoral</li>\n\t<li>Đơn h&agrave;ng &gt;= 500k c&aacute;c sản phẩm kh&aacute;ng sinh v&agrave; c&aacute;c sản phẩm kh&aacute;c v&agrave; EBM &ndash; chiết khấu 5%&nbsp;(&aacute;p dụng từ th&aacute;ng 4/2023)</li>\n\t<li>Đơn h&agrave;ng &gt;= 1 triệu c&aacute;c sản phẩm kh&aacute;ng sinh v&agrave; c&aacute;c sản phẩm kh&aacute;c v&agrave; EBM &ndash; chiết khấu 8%&nbsp;(&aacute;p dụng từ th&aacute;ng 4/2023)</li>\n</ul>\n\n<p><strong>C&oacute; hiệu lực</strong></p>\n\n<p>03.01.2023 &ndash; (15:00 / 31.12.2023)</p>\n\n<p><strong>Nội dung chi tiết</strong></p>\n\n<p>- Kh&ocirc;ng &aacute;p dụng đồng thời với c&aacute;c chương tr&igrave;nh kh&aacute;c</p>",
                "notes": "202301-DH-QD01-PMC-PCL-CTD",
                "promo_type": 2,
                "id_promo": 218,
                "id_promo_exclude": "210,211,212,213,214,215,216,217,276,277,278,288,289,290,295,296,297,298,319,320",
                "render_max": 0,
                "total_render_max": 0,
                "promo_budget_type": 0,
                "total_budget_max": 0,
                "total_amount_by_cart": 7390000,
                "total_amount_render": 591200,
                "total_quantity_product_render": 0,
                "condition_budget_type": 1,
                "total_amount_min": 1000000,
                "total_amount_max": 0,
                "total_quantity_min": 0,
                "total_quantity_max": 0,
                "renders": {
                    "discount": {
                        "discount": 8,
                        "amount": 591200
                    }
                },
                "contributes": [
                    {
                        "id_product": "2",
                        "amount": 91200,
                        "quantity_cart": 10
                    },
                    {
                        "id_product": "25",
                        "amount": 72000,
                        "quantity_cart": 10
                    },
                    {
                        "id_product": "26",
                        "amount": 76800,
                        "quantity_cart": 10
                    },
                    {
                        "id_product": "28",
                        "amount": 29600,
                        "quantity_cart": 10
                    },
                    {
                        "id_product": "29",
                        "amount": 72000,
                        "quantity_cart": 10
                    },
                    {
                        "id_product": "32",
                        "amount": 36000,
                        "quantity_cart": 10
                    },
                    {
                        "id_product": "59",
                        "amount": 105600,
                        "quantity_cart": 20
                    },
                    {
                        "id_product": "61",
                        "amount": 64000,
                        "quantity_cart": 10
                    },
                    {
                        "id_product": "63",
                        "amount": 44000,
                        "quantity_cart": 10
                    }
                ],
                "childrens": []
            },
            {
                "id_promo_info": 49,
                "id_group": 1,
                "promo_code": "202301-DH-QD01-PMC-PCL-CTD",
                "promo_code_dms": "CS2301I001",
                "promo_name": "Đơn hàng EBM (Ebysta, Benita, Medoral) - Mỗi 10 hộp tặng 1 hộp cùng loại",
                "promo_invoice_name": "Hàng khuyến mãi không thu tiền",
                "description": "CSBH 2301 - Nhóm PMC,PCL,CTD - ĐH EBM - 10 tặng 1 cùng loại",
                "contents": "<h1><strong>Ch&iacute;nh s&aacute;ch b&aacute;n h&agrave;ng năm&nbsp;2023</strong></h1>\n\n<p><strong>Ưu đ&atilde;i</strong></p>\n\n<ul>\n\t<li>Mua mỗi&nbsp;10 hộp&nbsp;sản phẩm Ebysta - Tặng 1 hộp&nbsp;Ebysta</li>\n\t<li>Mua mỗi&nbsp;10 hộp sản phẩm Benita - Tặng 1 hộp&nbsp;Benita</li>\n\t<li>Mua mỗi 10 hộp&nbsp;sản phẩm Medoral - Tặng 1 hộp&nbsp;Medoral</li>\n\t<li>Đơn h&agrave;ng &gt;= 500k c&aacute;c sản phẩm kh&aacute;ng sinh v&agrave; c&aacute;c sản phẩm kh&aacute;c v&agrave; EBM &ndash; chiết khấu 5%&nbsp;(&aacute;p dụng từ th&aacute;ng 4/2023)</li>\n\t<li>Đơn h&agrave;ng &gt;= 1 triệu c&aacute;c sản phẩm kh&aacute;ng sinh v&agrave; c&aacute;c sản phẩm kh&aacute;c v&agrave; EBM &ndash; chiết khấu 8%&nbsp;(&aacute;p dụng từ th&aacute;ng 4/2023)</li>\n</ul>\n\n<p><strong>C&oacute; hiệu lực</strong></p>\n\n<p>03.01.2023 &ndash; (15:00 / 31.12.2023)</p>\n\n<p><strong>Nội dung chi tiết</strong></p>\n\n<p>- Kh&ocirc;ng &aacute;p dụng đồng thời với c&aacute;c chương tr&igrave;nh kh&aacute;c</p>",
                "notes": "202301-DH-QD01-PMC-PCL-CTD",
                "promo_type": 2,
                "id_promo": 216,
                "id_promo_exclude": "210,211,212,213,214,215,217,218,276,277,278,288,289,290,295,296,297,298,319,320",
                "render_max": 0,
                "total_render_max": 0,
                "promo_budget_type": 0,
                "total_budget_max": 0,
                "total_amount_by_cart": 0,
                "total_amount_render": 0,
                "total_quantity_product_render": 0,
                "condition_budget_type": 2,
                "total_amount_min": 0,
                "total_amount_max": 0,
                "total_quantity_min": 0,
                "total_quantity_max": 0,
                "renders": {
                    "product": [
                        [
                            {
                                "id_product": 25,
                                "product_name": "Benita",
                                "vat": 5,
                                "unit_name": "Hộp",
                                "budget_max": 0,
                                "quantity": 1
                            },
                            {
                                "id_product": 29,
                                "product_name": "Medoral 250 ml",
                                "vat": 5,
                                "unit_name": "Hộp",
                                "budget_max": 0,
                                "quantity": 1
                            },
                            {
                                "id_product": 61,
                                "product_name": "Ebysta",
                                "vat": 5,
                                "unit_name": "Hộp",
                                "budget_max": 0,
                                "quantity": 1
                            }
                        ]
                    ]
                },
                "contributes": [
                    {
                        "id_product": "25",
                        "amount": 0,
                        "quantity_cart": 10
                    },
                    {
                        "id_product": "29",
                        "amount": 0,
                        "quantity_cart": 10
                    },
                    {
                        "id_product": "61",
                        "amount": 0,
                        "quantity_cart": 10
                    }
                ],
                "childrens": []
            },
            {
                "id_promo_info": 75,
                "id_group": 1,
                "promo_code": "202307-DH-CPA25-PMC-PCL-CTD-SI",
                "promo_code_dms": "KM2307I001",
                "promo_name": "ĐH XOS (Xisat, Osla, Shema) trên 500k – Tặng 1 hộp Xisat trẻ em",
                "promo_invoice_name": "Hàng khuyến mãi không thu tiền",
                "description": "KM 2307-PMC/PCL/CTD/SI- ĐH XOS 500K tặng 1 Xisat Hồng",
                "contents": "<h1><strong>Chương tr&igrave;nh khuyến m&atilde;i giỏ h&agrave;ng XOS</strong></h1>\n\n<p><strong>Ưu đ&atilde;i</strong></p>\n\n<ul>\n\t<li>Đơn h&agrave;ng &gt;=500k tr&ecirc;n đơn XOS (Xisat, Osla, Shema) &ndash; Tặng 1 Xisat trẻ em</li>\n\t<li>Đơn h&agrave;ng &gt;=1 triệu tr&ecirc;n đơn XOS (Xisat, Osla, Shema) &ndash; Tặng 2 hộp Xisat người lớn&nbsp;75ml (Xanh) v&agrave; 1 hộp Benate 5g</li>\n\t<li>Đơn h&agrave;ng &gt;= 3 triệu tr&ecirc;n đơn XOS (Xisat, Osla, Shema) &ndash; Tặng 7&nbsp;hộp Xisat người lớn&nbsp;75ml (Xanh)&nbsp;v&agrave; 1 hộp Medoral Active</li>\n\t<li>Đơn h&agrave;ng &gt;=5 triệu tr&ecirc;n đơn XOS (Xisat, Osla, Shema) &nbsp;&ndash; Tặng 12 hộp Xisat người lớn&nbsp;75ml (Xanh)&nbsp;v&agrave; 1 hộp Ebysta 10ml g&oacute;i</li>\n</ul>\n\n<p><strong>C&oacute; hiệu lực</strong></p>\n\n<p>01.07.2023 &ndash; (15:00 / 31.07.2023)</p>\n\n<p><strong>Nội dung chi tiết</strong></p>\n\n<p>&Aacute;p dụng: sản phẩm đơn XOS (Xisat Osla, Shema)</p>",
                "notes": "Chương trình khuyến mãi giỏ hàng XOS Tháng 07/2023",
                "promo_type": 1,
                "id_promo": 311,
                "id_promo_exclude": "319,320",
                "render_max": 0,
                "total_render_max": 0,
                "promo_budget_type": 0,
                "total_budget_max": 0,
                "total_amount_by_cart": 815000,
                "total_amount_render": 0,
                "total_quantity_product_render": 0,
                "condition_budget_type": 1,
                "total_amount_min": 500000,
                "total_amount_max": 999999,
                "total_quantity_min": 0,
                "total_quantity_max": 0,
                "renders": {
                    "product": [
                        [
                            {
                                "id_product": 18,
                                "product_name": "Dung dịch vệ sinh mũi nước biển sâu Xisat trẻ em (75 ml)",
                                "vat": 5,
                                "unit_name": "Hộp",
                                "budget_max": 0,
                                "quantity": 1
                            }
                        ]
                    ]
                },
                "contributes": [
                    {
                        "id_product": "108",
                        "amount": 0,
                        "quantity_cart": 6.134969325153374
                    },
                    {
                        "id_product": "70",
                        "amount": 0,
                        "quantity_cart": 6.134969325153374
                    },
                    {
                        "id_product": "71",
                        "amount": 0,
                        "quantity_cart": 6.134969325153374
                    }
                ],
                "childrens": []
            },
            {
                "id_promo_info": 77,
                "id_group": 1,
                "promo_code": "202307-DH-CPA25-PMC-PCL-CTD-SI-SHE",
                "promo_code_dms": "KM2307I003",
                "promo_name": "Mỗi 12 hộp Shema 100ml (Hồng Hoặc Xanh) - Tặng 2 hộp Shema 100ml Hồng",
                "promo_invoice_name": "Hàng khuyến mãi không thu tiền",
                "description": "Mỗi 12 hộp Shema 100ml (Hồng Hoặc Xanh) - Tặng 2 hộp Shema 100ml Hồng",
                "contents": null,
                "notes": "KM 2307-PMC/PCL/CTD/SI- ĐH 12 Shema 100 (H/X) tặng 2 Shema 100H/24 Shema 100 (H/X) tặng 6 Shema 100H",
                "promo_type": 1,
                "id_promo": 317,
                "id_promo_exclude": "319,320",
                "render_max": 0,
                "total_render_max": 0,
                "promo_budget_type": 0,
                "total_budget_max": 0,
                "total_amount_by_cart": 470000,
                "total_amount_render": 0,
                "total_quantity_product_render": 0,
                "condition_budget_type": 1,
                "total_amount_min": 0,
                "total_amount_max": 0,
                "total_quantity_min": 12,
                "total_quantity_max": 23,
                "renders": {
                    "product": [
                        [
                            {
                                "id_product": 70,
                                "product_name": "Shema lá đôi (hồng - 100 ml)",
                                "vat": 10,
                                "unit_name": "Hộp",
                                "budget_max": 0,
                                "quantity": 2
                            }
                        ]
                    ]
                },
                "contributes": [
                    {
                        "id_product": "70",
                        "amount": 0,
                        "quantity_cart": 6
                    },
                    {
                        "id_product": "71",
                        "amount": 0,
                        "quantity_cart": 6
                    }
                ],
                "childrens": []
            },
            {
                "id_promo_info": 79,
                "id_group": 2,
                "promo_code": "202306-DH-CPA22-PMC",
                "promo_code_dms": "KM2306M001",
                "promo_name": "Đơn đặt hàng qua Website >= 250k ck 2% (tối đa 50k/ĐH)",
                "promo_invoice_name": "Chiết khấu đơn hàng khách hàng tham gia chương trình Ecommerce",
                "description": null,
                "contents": null,
                "notes": "Đơn đặt hàng qua Website >= 250k ck 2% (tối đa 50k/ĐH)",
                "promo_type": 1,
                "id_promo": 322,
                "id_promo_exclude": "306,307,321",
                "render_max": 0,
                "total_render_max": 1,
                "promo_budget_type": 0,
                "total_budget_max": 0,
                "total_amount_by_cart": 9555000,
                "total_amount_render": 150000,
                "total_quantity_product_render": 0,
                "condition_budget_type": 1,
                "total_amount_min": 2500000,
                "total_amount_max": 0,
                "total_quantity_min": 0,
                "total_quantity_max": 0,
                "renders": {
                    "money": 150000
                },
                "contributes": [
                    {
                        "id_product": "108",
                        "amount": 0,
                        "quantity_cart": 7.849293563579278
                    },
                    {
                        "id_product": "2",
                        "amount": 0,
                        "quantity_cart": 7.849293563579278
                    },
                    {
                        "id_product": "25",
                        "amount": 0,
                        "quantity_cart": 7.849293563579278
                    },
                    {
                        "id_product": "26",
                        "amount": 0,
                        "quantity_cart": 7.849293563579278
                    },
                    {
                        "id_product": "28",
                        "amount": 0,
                        "quantity_cart": 7.849293563579278
                    },
                    {
                        "id_product": "29",
                        "amount": 0,
                        "quantity_cart": 7.849293563579278
                    },
                    {
                        "id_product": "32",
                        "amount": 0,
                        "quantity_cart": 7.849293563579278
                    },
                    {
                        "id_product": "58",
                        "amount": 0,
                        "quantity_cart": 7.849293563579278
                    },
                    {
                        "id_product": "59",
                        "amount": 0,
                        "quantity_cart": 15.698587127158556
                    },
                    {
                        "id_product": "61",
                        "amount": 0,
                        "quantity_cart": 7.849293563579278
                    },
                    {
                        "id_product": "63",
                        "amount": 0,
                        "quantity_cart": 7.849293563579278
                    },
                    {
                        "id_product": "70",
                        "amount": 0,
                        "quantity_cart": 7.849293563579279
                    },
                    {
                        "id_product": "71",
                        "amount": 0,
                        "quantity_cart": 7.849293563579279
                    }
                ],
                "childrens": []
            },
            {
                "id_promo_info": 79,
                "id_group": 1,
                "promo_code": "202306-DH-CPA22-PMC",
                "promo_code_dms": "KM2306M001",
                "promo_name": "Đơn đặt hàng qua Website >= 250k ck 2% (tối đa 50k/ĐH)",
                "promo_invoice_name": "Chiết khấu đơn hàng khách hàng tham gia chương trình Ecommerce",
                "description": null,
                "contents": null,
                "notes": "Đơn đặt hàng qua Website >= 250k ck 2% (tối đa 50k/ĐH)",
                "promo_type": 1,
                "id_promo": 321,
                "id_promo_exclude": "306,307,322",
                "render_max": 0,
                "total_render_max": 1,
                "promo_budget_type": 0,
                "total_budget_max": 0,
                "total_amount_by_cart": 2055000,
                "total_amount_render": 41100,
                "total_quantity_product_render": 0,
                "condition_budget_type": 1,
                "total_amount_min": 250000,
                "total_amount_max": 2499999,
                "total_quantity_min": 0,
                "total_quantity_max": 0,
                "renders": {
                    "discount": {
                        "discount": 2,
                        "amount": 41100
                    }
                },
                "contributes": [
                    {
                        "id_product": "108",
                        "amount": 1483.9874411302983,
                        "quantity_cart": 2.1507064364207222
                    },
                    {
                        "id_product": "2",
                        "amount": 4903.610675039246,
                        "quantity_cart": 2.1507064364207222
                    },
                    {
                        "id_product": "25",
                        "amount": 3871.2715855573,
                        "quantity_cart": 2.1507064364207222
                    },
                    {
                        "id_product": "26",
                        "amount": 4129.356357927787,
                        "quantity_cart": 2.1507064364207222
                    },
                    {
                        "id_product": "28",
                        "amount": 1591.5227629513347,
                        "quantity_cart": 2.1507064364207222
                    },
                    {
                        "id_product": "29",
                        "amount": 3871.2715855573,
                        "quantity_cart": 2.1507064364207222
                    },
                    {
                        "id_product": "32",
                        "amount": 1935.63579277865,
                        "quantity_cart": 2.1507064364207222
                    },
                    {
                        "id_product": "58",
                        "amount": 5806.90737833595,
                        "quantity_cart": 2.1507064364207222
                    },
                    {
                        "id_product": "59",
                        "amount": 5677.864992150707,
                        "quantity_cart": 4.3014128728414445
                    },
                    {
                        "id_product": "61",
                        "amount": 3441.1302982731554,
                        "quantity_cart": 2.1507064364207222
                    },
                    {
                        "id_product": "63",
                        "amount": 2365.7770800627945,
                        "quantity_cart": 2.1507064364207222
                    },
                    {
                        "id_product": "70",
                        "amount": 1010.8320251177392,
                        "quantity_cart": 2.150706436420722
                    },
                    {
                        "id_product": "71",
                        "amount": 1010.8320251177392,
                        "quantity_cart": 2.150706436420722
                    }
                ],
                "childrens": []
            }
        ],
        "accumulations": [
            {
                "id_accum_info": 2,
                "id_group": 1,
                "accum_code": "TL-TTMB",
                "accum_code_dms": "TL-TTMB",
                "accum_name": "Chương trình tích lũy có TTMB-ĐH tích lũy quý Kháng sinh>= 9triệu",
                "accum_invoice_name": "\"đơn tích lũy: Chiết khấu đạt doanh số theo TTMB số……, ngày… đơn quyết toán: Chiết khấu đạt doanh số theo TTMB số……, ngày… (theo bảng kê đính kèm)\"",
                "description": null,
                "contents": "<p>Chương tr&igrave;nh t&iacute;ch lũy c&oacute; TTMB</p>",
                "notes": "Chương trình tích lũy có TTMB",
                "accum_type": 5,
                "id_accum": 2,
                "render_max": 0,
                "total_amount_by_cart": 11400000,
                "total_amount_render": 1368000,
                "total_quantity_product_render": 0,
                "total_amount_min": 9000000,
                "total_amount_max": 18000000,
                "total_quantity_min": 0,
                "total_quantity_max": 0,
                "status_closing": 0,
                "renders": {
                    "discount": {
                        "discount": 12,
                        "amount": 1368000,
                        "total_prepay": 1140000,
                        "total_payable": 228000
                    }
                },
                "contributes": [
                    {
                        "id_product": 2,
                        "amount": 1368000,
                        "quantity_cart": 100
                    }
                ],
                "childrens": []
            }
        ],
        "liabilities": [
            {
                "id_accum_info": 2,
                "id_agreement": 1,
                "agreement_code": "DEMOCTTL-TTMB2023",
                "agreement_name": "Chương trình tích lũy kháng sinh-stomex ck10%",
                "accum_code": "TL-TTMB",
                "accum_name_reg": "Chương trình tích lũy có TTMB",
                "total_debit": 1397520, // tổng nợ
                "total_prepay": 1065600,
                "total_payment": 331920,
                "total_payable": 331920,
                "discount": 10,
            },
            {
                "id_accum_info": 2,
                "id_agreement": 2,
                "agreement_code": "DEMOCTTL-TTMB2023",
                "agreement_name": "Chương trình tích lũy kháng sinh-stomex ck8%",
                "accum_code": "TL-TTMB",
                "accum_name_reg": "Chương trình tích lũy có TTMB",
                "total_debit": 1397520, // tổng nợ
                "total_prepay": 1065600,
                "total_payment": 200000,
                "total_payable": 200000,
                "discount": 8,
            },

        ]
    },
    "error": 0,
    "message": "Success"
}