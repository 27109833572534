import React from 'react';
import PropTypes from 'prop-types';


const UserIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.2003 0C11.7316 0 12.2629 0 12.7942 0C12.8619 0.0150323 12.9286 0.0352319 12.9967 0.0441574C13.4214 0.100528 13.8512 0.129654 14.2707 0.212801C17.2203 0.8 19.6255 2.28491 21.482 4.64686C22.7038 6.20129 23.4841 7.96007 23.8148 9.91239C23.8876 10.3413 23.9388 10.7739 23.9999 11.2052C23.9999 11.7365 23.9999 12.2678 23.9999 12.7991C23.9637 13.0922 23.9327 13.3858 23.8899 13.678C23.5503 15.9977 22.6498 18.074 21.0911 19.8281C18.2209 23.0586 14.6075 24.4082 10.3266 23.8858C8.00833 23.6031 5.93951 22.6673 4.19295 21.1124C0.769811 18.0655 -0.566654 14.2412 0.217844 9.73717C0.738337 6.74809 2.3017 4.33729 4.69606 2.47657C6.23875 1.27775 7.9792 0.511568 9.90803 0.185555C10.3369 0.112742 10.7696 0.0610687 11.2003 0ZM9.13199 13.1763C6.17768 11.0403 6.51966 7.26671 8.43017 5.40646C10.4332 3.45602 13.5989 3.46494 15.5799 5.42102C17.4895 7.30664 17.792 11.0563 14.8668 13.1725C17.5083 14.2426 19.2187 16.1484 20.0041 18.889C22.4468 16.1841 23.454 11.7393 21.6966 7.7468C19.9083 3.68479 15.5668 0.871873 10.6324 1.49947C6.40222 2.03782 2.66106 5.19131 1.64967 9.74187C1.28983 11.3621 1.3307 12.9813 1.73329 14.5884C2.13634 16.1973 2.88467 17.6277 3.98625 18.9078C4.7778 16.1325 6.5004 14.2473 9.13199 13.1763ZM12.1727 22.594C12.4245 22.5743 12.8384 22.5541 13.2485 22.5071C15.1266 22.291 16.8055 21.5868 18.3402 20.4862C18.7456 20.1954 18.7837 19.9131 18.6808 19.4767C18.2669 17.7193 17.3349 16.3166 15.8571 15.2888C14.4976 14.3432 12.9699 13.9725 11.3305 14.1257C9.89347 14.26 8.61667 14.8171 7.52917 15.7703C6.24908 16.8925 5.46975 18.2967 5.24004 19.9915C5.23017 20.0629 5.27762 20.1719 5.3354 20.218C7.2849 21.7846 9.51485 22.5668 12.1727 22.594ZM15.6391 8.98415C15.6288 6.98203 13.9898 5.34445 11.9971 5.34492C10.0034 5.34539 8.36441 6.98391 8.35595 8.98509C8.3475 10.9919 9.99494 12.6323 12.0097 12.6229C14.0217 12.614 15.6494 10.982 15.6391 8.98415Z" fill="white"/>
        </svg>

    );
};


UserIcon.propTypes = {

};


export default UserIcon;
