import produce from 'immer';
import * as constants from './constants';
//import _ from 'lodash';

const initialState = {
    isloadingCat: false,
    isLoadingTag: false,
    isLoadingPost: false,
    isLoadingPostPop: false,
    tags: [],
    categorys: [],
    postPops: [],
    posts: {
        data: [],
        total: 0
    },
    isLoadingDetail: false,
    postDetail: {}
}

const reducer = (state = initialState, { type, payload }) =>
    produce(state, draft => {

        switch (type) {

            case constants.GET_CATEGORY_POST_START:
                draft.isloadingCat = true;
                draft.categorys = [];
            break;

            case constants.GET_CATEGORY_POST_SUCCESS:
                draft.isloadingCat = false;
                draft.categorys = payload;
            break;

            case constants.GET_CATEGORY_POST_ERROR:
                draft.isloadingCat = false;
                draft.categorys = [];
            break;


            case constants.GET_TAG_POST_START:
                draft.isLoadingTag = true;
                draft.tags = [];
            break;

            case constants.GET_TAG_POST_SUCCESS:
                draft.isLoadingTag = false;
                draft.tags = payload;
            break;

            case constants.GET_TAG_POST_ERROR:
                draft.isLoadingTag = false;
                draft.tags = [];
            break;


            case constants.GET_POP_POST_START:
                draft.isLoadingPostPop = true;
                draft.postPops = [];
            break;

            case constants.GET_POP_POST_SUCCESS:
                draft.isLoadingPostPop = false;
                draft.postPops = payload;
            break;

            case constants.GET_POP_POST_ERROR:
                draft.isLoadingPostPop = false;
                draft.postPops = [];
            break;


            case constants.GET_POST_START:
                draft.isLoadingPost = true;

            break;

            case constants.GET_POST_SUCCESS:
                draft.isLoadingPost = false;
                draft.posts = payload;
            break;

            case constants.GET_POST_ERROR:
                draft.isLoadingPost = false;
                draft.posts = {data: [], total: 0};
            break;

            case constants.GET_POST_DETAIL_START:
                draft.postDetail = {};
                draft.isLoadingDetail = true;
            break;

            case constants.GET_POST_DETAIL_SUCCESS:
                draft.postDetail = payload;
                draft.isLoadingDetail = false;
            break;

            case constants.GET_POST_DETAIL_ERROR:
                draft.postDetail = {};
                draft.isLoadingDetail = false;

            break;
            

            case `RESET_STATE`:
                return initialState;

            default:
                break;
        }
    });

export default reducer;