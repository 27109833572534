import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';

import {
    makeStyles,
    Typography
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import * as constants from './constants';


const useStyles = makeStyles((theme) => ({
    rootModal: {
        maxHight: 'initial',
        
    },
    dialogTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    iconClose: {
        cursor: 'pointer',
        color: '#3C5264',
        width: 24,
        height: 24
    },
    rootDialog: {
        maxWidth: 600,
        padding: 20,
        [theme.breakpoints.down(500)]: {
            padding: 10
        }
        
    },
    content: {
        textAlign: 'center',
        padding: 20,
        minWidth: 400,
        
        [theme.breakpoints.down(500)]: {
            minWidth: 'auto',
            padding: 10
        }
    },
    contentTitle: {
        fontWeight: 600,
        fontSize: 18
    },
    contentText: {
        marginTop: 15
    },
    groupBtn: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 30,
        justifyContent: 'center',
        '&>button': {
            borderRadius: 40,
            width: 150,
            height: 40,
            margin: 5,
            [theme.breakpoints.down(500)]: {
                textTransform: 'capitalize',
            }
        }
    }
}));

const ModalConfirmOrder = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { 
        open, data 
    } = useSelector(state => state.modalConfirm);


    const handleClose = () => {
        dispatch({type: constants.CLOSE_MODAL_CONFIRM});

    };

    const handleConfirm = () => {
        dispatch({type: constants.CLOSE_MODAL_CONFIRM});
        if (data.onConfirm) {
            data.onConfirm();
        }
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.rootModal}
            >
                <div className={classes.rootDialog}>
                {data.close && data.close === true && (
                    <div className={classes.dialogTitle} >
                        <div style={{flexGrow: 1}} />
                            <CloseIcon 
                                className={classes.iconClose} 
                                onClick={handleClose}
                            />
                    </div>
                )}
                    

                    <div className={classes.content}>
                        {data.title && (<div className={classes.contentTitle}>{data.title}</div>)}
                        {data.text && (<Typography className={classes.contentText}>{data.text}</Typography>)}

                        <div className={classes.groupBtn}>
                            {data.type && data.type === 'confirm' && (
                                <Button
                                    variant='outlined'
                                    color='default'
                                    onClick={handleConfirm}
                                >{data.txtConfirm}</Button>
                            )}
                            
                            <Button 
                                onClick={handleClose}
                                variant='contained'
                                color='primary'
                            >{data.txtCancel}</Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default ModalConfirmOrder;