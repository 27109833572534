import ascusapi from '../../../apis/ascusapi';

export const getCategorys = async (params) => { // lấy danh sách cat
    const respone = await ascusapi.get('ecommerce/categories', params);
    return respone;
}

export const getProducts = async (params) => { // lấy danh sách pdt
    const respone = await ascusapi.get('ecommerce/products', {params});
    return respone;
}

export const getListProducts = async (params) => { // lấy danh sách phân loại sản phẩm
    const respone = await ascusapi.get('ecommerce/get-product-filed', {params});
    return respone;
}

export const clearCartOrder = async () => { // xóa giỏ hàng
    const respone = await ascusapi.post('ecommerce/order/clear-cart');
    return respone;
}


export const updateCartOrder = async (data) => { // cập nhật giỏ hàng
    const respone = await ascusapi.post('ecommerce/order/update-cart', data);
    return respone;
}


export const getCartOrder = async () => { // cập nhật giỏ hàng
    const respone = await ascusapi.post('ecommerce/order/get-cart');
    return respone;
}

export const getBanner = async (params) => { // danh sách banner
    const respone = await ascusapi.get('ecommerce/banner', {params});
    return respone;
}

export const getNews = async (params) => { // danh sách bài viết
    const respone = await ascusapi.get('ecommerce/post', params);
    return respone;
}
