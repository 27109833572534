import getStore from '../pages/configureStore';
import * as constants from './../components/ModalConfirmOrder/constants';
export const confirmCancelOrder = (callback) => {
    const data = {
        title: 'Huỷ Đơn Hàng',
        text: 'Bạn xác nhận muốn gửi yêu cầu hủy đơn hàng',
        close: true,
        txtCancel: 'Bỏ qua',
        txtConfirm: 'Hủy đơn hàng',
        type: 'confirm',
        onConfirm: () => {
            callback();
        }
    };


    getStore().dispatch({
        type: constants.OPEN_MODAL_CONFIRM,
        payload: data
    })
}


export const infoSuccess = ({title, text},callback) => {
    const data = {
        title: title,
        text: text,
        close: false,
        txtCancel: 'Xác nhận',
        txtConfirm: 'Xác nhận',
        type: 'info',
        onConfirm: () => {
            callback();
        }
    };


    getStore().dispatch({
        type: constants.OPEN_MODAL_CONFIRM,
        payload: data
    })
}